<template>
  <div>
    <v-autocomplete v-model="selectedProduct" hide-no-data ref="productSearchInput" @keyup.enter="searchIMEI" outlined
      :loading="productSearchLoading" prepend-inner-icon="search" rounded allow-overflow dense single-line
      :error-messages="error" :hide-details="error.length === 0" clearable eager id="productSearch" :items="Products"
      item-text="name" return-object :search-input.sync="phoneSearch" :label="$t('labels.searchProduct')"
      :items-per-page="20" :filter="customFilter">
      <template v-slot:prepend-item>
        <v-list-item ripple @click="openMultipleProductsDialog">
          <v-list-item-content>
            <v-list-item-title>Choose Multiple Items</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-1"></v-divider>
      </template>
      <template slot="item" slot-scope="{ item }">
        <div>
          <div v-html="$highlight(item.name, phoneSearch)">{{ item.name }}</div>
          <small v-if="item.dynamicPrice === item.price" class="red--text">
            {{ item.dynamicPrice | currency }} *
          </small>
          <small v-else>{{ item.price | currency }}</small>
        </div>
        <v-spacer></v-spacer>
        <v-chip v-if="item.trackInventory" :color="item.trackSerialNumber ? 'light-blue darken-1' : 'teal darken-1'"
          dark>
          <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
            <v-icon small>mdi-barcode-scan</v-icon>
          </v-avatar>
          <v-avatar left v-else class="teal darken-3">
            <v-icon small>mdi-package-variant-closed</v-icon>
          </v-avatar>
          <span>{{ item.balance }}</span>
        </v-chip>
      </template>
    </v-autocomplete>

    <v-dialog v-model="multipleProductsDialog" width="550">
      <v-card>
        <ModelTitle title="Add Items" @close="closeMultipleProductsDialog()" />
        <v-container class="pa-5" grid-list-lg>
          <v-layout row wrap justify-center align-center>
            <v-text-field v-if="!selectedProducts.length" v-model="searchProduct" outlined prepend-inner-icon="search"
              rounded dense label="Search" single-line hide-details clearable></v-text-field>
            <v-spacer v-if="selectedProducts.length"></v-spacer>
            <v-btn color="info" v-if="selectedProducts.length" @click="addToCart"> Add To Cart</v-btn>
            <v-list class="list-350">
              <template v-for="(product, index) in NonSerializedProducts">
                <v-list-item :key="index">
                  <v-list-item-action>
                    <v-checkbox v-model="product.selected" @click="productSelected(product)"
                      hide-details=""></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ product.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ product.price | currency }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="product.selected">
                    <v-flex shrink class="ma-0">
                      <v-btn dark fab x-small color="red" depressed @click.stop.prevent="decrementQuantity(product)">
                        <v-icon>mdi-minus-thick</v-icon>
                      </v-btn>
                      {{ product.qty }}
                      <v-btn dark fab x-small color="info" depressed @click.stop.prevent="incrementQuantity(product)">
                        <v-icon>mdi-plus-thick</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-list-item-action>
                </v-list-item>
                <!-- <v-divider v-if="index + 1 < Products.length" :key="index"> -->
                <!-- </v-divider> -->
              </template>
            </v-list>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" :disabled="!cartProducts.length" @click="submitMultiple"> Add {{ cartProducts.length }} {{
            cartProducts.length > 1 ? 'Items' : 'Item' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Products: {{ Products }} -->
  </div>

</template>

<script>
import productService from "@/modules/Product/service";
import { mapGetters, mapActions } from "vuex";
import purchaseService from "@/modules/Inventory/Purchase/service";
export default {
  name: 'product-autocomplete',
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: null,
      error: [],
      Products: [],
      selectedProduct: null,
      NonSerializedProducts: [],
      search: null,
      productSearchLoading: false,
      multipleProductsDialog: false,
      selectedProducts: [],
      phoneSearch: null,
      cartProducts: [],
      searchProduct: null,
    }
  },
  watch: {
    selectedProduct(val) {
      // console.log('selectedProduct', val)
      if (val) {
        this.$emit('submit', val)
      }
    },
    phoneSearch(val) {
      if (val && val.length > 2) {
        this.getProduct();
      }
      // if (val && val.length > 1) this.error = [];
    },
    searchProduct(val) {
      this.fetchNonSerializedProducts();
    },
  },
  computed: {
    ...mapGetters("invoice", ["Invoice", "InvoiceDetails"]),
  },
  methods: {
    ...mapActions("invoice", ["AddInvoiceDetail"]),
    ...mapActions("global", ["PlaySound"]),
    customFilter(item, queryText) {
      if (!queryText) return true;
      const terms = queryText.split(' ').filter(Boolean); // Split input by spaces
      const regex = new RegExp(terms.map(term => `(?=.*${term})`).join(''), 'i'); // RegExp to match terms in any order
      return regex.test(item.name);
    },
    emptyEntryBar() {
      this.$emit('emptyEntryBar')
      this.selectedProduct = null
      this.phoneSearch = null
      // console.log('empty entry bar call')
    },
    emptyAutoComplete() {
      // console.log('emptyAutoComplete')
      this.selectedProduct = null
    },
    productSelected(val) {
      if (val.selected)
        this.selectedProducts.push(val)
      else {
        const index = this.selectedProducts.findIndex(
          (x) => x.id === val.id
        );
        this.selectedProducts.splice(index, 1);
      }
    },
    // getProduct() {
    //   return productService.getAll({ search: this.phoneSearch, hideParent: true }).then((result) => {
    //     const product = result.data;
    //     this.Products = product
    //   });
    // },
    getProduct() {
      return productService.getAll({ search: this.phoneSearch, hideParent: true }).then((result) => {
        const product = result.data;

        // Sort products based on the closeness to the search query
        this.Products = product.sort((a, b) => {
          const query = this.phoneSearch.toLowerCase();

          // Check if the name starts with the search query
          const aStartsWith = a.name.toLowerCase().startsWith(query) ? 0 : 1;
          const bStartsWith = b.name.toLowerCase().startsWith(query) ? 0 : 1;

          // Prioritize products whose name starts with the search query
          if (aStartsWith !== bStartsWith) {
            return aStartsWith - bStartsWith;
          }

          // If both start or neither starts, use indexOf to check proximity
          const aIndex = a.name.toLowerCase().indexOf(query);
          const bIndex = b.name.toLowerCase().indexOf(query);

          if (aIndex !== bIndex) {
            return aIndex - bIndex; // Closer to the start gets higher priority
          }

          // Finally, sort alphabetically if all else is equal
          return a.name.localeCompare(b.name);
        });
      });
    },
    fetchNonSerializedProducts() {
      if (this.searchProduct && this.searchProduct.length < 3) {
        return productService.getAll({ limit: 50, hideParent: true, inventoryType: 'nonSerialize' }).then((result) => {
          this.NonSerializedProducts = []
          const products = result.data;
          products.map((row) => {
            row.selected = false
            row.qty = 1
            this.NonSerializedProducts.push(row)
          });
        });
      } else {
        return productService.getAll({ hideParent: true, inventoryType: 'nonSerialize', search: this.searchProduct }).then((result) => {
          this.NonSerializedProducts = []
          const products = result.data;
          products.map((row) => {
            row.selected = false
            row.qty = 1
            this.NonSerializedProducts.push(row)
          });
        });
      }
    },
    addToCart() {
      if (!this.selectedProducts.length) return false
      this.selectedProducts.map(product => {
        product.Bar = {
          name: product.name,
          qty: product.qty,
          price: product.price,
          boxQty: product.qty,
        };
        this.cartProducts.push(product)
      })
      this.selectedProducts = []
      this.NonSerializedProducts.map((row) => {
        row.selected = false
        row.qty = 1
      });
    },
    submitMultiple() {
      if (!this.cartProducts.length) return false
      this.$emit("submitMultiple", this.cartProducts);
      this.closeMultipleProductsDialog()
    },
    openMultipleProductsDialog() {
      this.multipleProductsDialog = true
      this.fetchNonSerializedProducts()
    },
    closeMultipleProductsDialog() {
      this.multipleProductsDialog = false,
        this.cartProducts = [],
        this.NonSerializedProducts = [],
        this.selectedProducts = [],
        this.searchProduct = null
    },
    async searchIMEI() {
      this.error = []
      if (!this.phoneSearch) return false;
      if (this.selectedProduct) return false;

      const searchValue = {
        imeiNumber: this.phoneSearch,
      };

      const imeiResult = this.InvoiceDetails.findIndex((row) => {
        // // console.log('searchValue.imeiNumber', searchValue.imeiNumber)
        // // console.log(
        //   "searchValue.imeiNumber",
        //   row,
        //   searchValue.imeiNumber.toUpperCase()
        // );
        if (row.imeiNumber) row.imeiNumber = row.imeiNumber.toUpperCase();
        if (searchValue.imeiNumber)
          searchValue.imeiNumber = searchValue.imeiNumber.toUpperCase();
        if (row.imeiNumber === searchValue.imeiNumber) {
          return true;
        }
      });
      // console.log("passed!", imeiResult); //355299070455844

      if (imeiResult !== -1) {
        if (this.InvoiceDetails[imeiResult].delete) {
          await this.UnDeleteInvoiceDetailRow(imeiResult);
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "success",
              title: "Item was restore",
            });

          this.emptyEntryBar();
          return false;
        }
        this.error.push(`${searchValue.imeiNumber} is already in this list`);
        this.productSearchLoading = false;
        this.PlaySound("error");
        // this.$swal
        //   .mixin({
        //     toast: true,
        //     position: "top-right",
        //     showConfirmButton: true,
        //     timer: 3000,
        //   })
        //   .fire({
        //     icon: "error",
        //     title: `${searchValue.imeiNumber} is already in this list`,
        //   });
        this.$swal({
          title: "Product Not Found",
          text: `${searchValue.imeiNumber} is already in this list`,
          icon: "warning",
        });
        this.emptyEntryBar();
      } else {
        // console.log('i am here')
        this.productSearchLoading = true;
        // console.log('searching for upc code', searchValue)
        searchValue.filteredStatus = ['repair', 'onHold', 'available']
        return purchaseService
          .search(searchValue)
          .then(async (response) => {
            console.log("response imei number", response);
            if (response.data) {
              if (response.data.status === 'repair') {
                this.error.push(
                  `${this.phoneSearch} is on repair please bring it on available before you can sell it`
                );
                this.productSearchLoading = false;
                this.PlaySound("error");
                this.$swal({
                  title: "Status On Repair",
                  text: `${this.phoneSearch} is on repair please bring it on available before you can sell it`,
                  icon: "warning",
                });
                return true
              }
              if (response.data.status === 'onHold') {
                this.error.push(
                  `${this.phoneSearch} is on hold please bring it on available before you can sell it`
                );
                this.productSearchLoading = false;
                this.PlaySound("error");
                this.$swal({
                  title: "Status On Hold",
                  text: `${this.phoneSearch} is on hold please bring it on available before you can sell it`,
                  icon: "warning",
                });
                return true
              }
              this.productSearchLoading = false;
              let product = response.data;
              // product.Product = response.data.Product
              product.Product.imeiNumber = this.phoneSearch;
              // // console.log("response.data", response.data);
              // this.selectedProduct = response.data.Product;
              //     this.selectedProduct.Bar = {
              //   name: this.name,
              //   qty: this.qty,
              //   price: this.price,
              //   boxQty: this.boxQty,
              // };
              // console.log('product', product)

              this.AddInvoiceDetail({
                ProductId: product.Product.id,
                name: product.Product.name,
                price: product.Product.price,
                total: product.Product.price * 1,
                qty: 1,
                boxQty: 1,
                // unitCountInBox: product.Product.unitCountInBox,
                cost: product.cost | 0,
                imeiNumber: product.imeiNumber,
              });

              this.emptyEntryBar();
            } else {

              const upcProduct = (await productService.upcSearch({ upc: this.phoneSearch })).data

              if (upcProduct) {
                console.log('found upcProduct', upcProduct)
                let total = 0
                // if (upcProduct.unitCountInBox && upcProduct.unitCountInBox > 0 && this.currentBusiness?.metadata?.boxCountOnInventory) {
                //   total = ((1 * upcProduct.unitCountInBox) * upcProduct.boxPrice).toFixed(2)
                //   total = parseFloat(total)
                // } else {
                //   total = upcProduct.price * 1
                // }
                if (this.currentBusiness?.metadata?.UPCbehave) {
                  total = upcProduct.price * upcProduct.unitCountInBox
                  this.AddInvoiceDetail({
                    ProductId: upcProduct.id,
                    name: upcProduct.name,
                    price: upcProduct.price,
                    total: total,
                    qty: upcProduct.unitCountInBox,
                    boxQty: 1,
                    unitCountInBox: upcProduct.unitCountInBox,
                    cost: upcProduct.cost | 0,
                  });
                  this.emptyEntryBar();
                  this.productSearchLoading = false;
                } else {
                  // this.selectedProduct = upcProduct
                  this.productSearchLoading = false;
                  this.$emit('submit', upcProduct)
                  this.selectedProduct = upcProduct
                  // this.$refs.qty.focus
                }
                // C75334982222

              } else {
                this.error.push(
                  `${this.phoneSearch} No Product with this search`
                );
                this.productSearchLoading = false;
                this.PlaySound("error");
                // this.$swal
                //   .mixin({
                //     toast: true,
                //     position: "top-right",
                //     showConfirmButton: true,
                //     timer: 3000,
                //   })
                //   .fire({
                //     icon: "error",
                //     title: `${this.phoneSearch} No Product with this serach`,
                //   });
                this.$swal({
                  title: "Product Not Found",
                  text: `${this.phoneSearch} No Product with this serach`,
                  icon: "warning",
                });
                this.emptyEntryBar();
              }

            }
          })
          .catch((error) => {
            this.productSearchLoading = false;
            this.error.push(
              `${this.phoneSearch} No Product with this search`
            );
            this.productSearchLoading = false;
            this.PlaySound("error");
            this.$swal({
              title: "Product Not Found",
              text: `${this.phoneSearch} No Product with this serach`,
              icon: "warning",
            });
            // this.$swal
            //   .mixin({
            //     toast: true,
            //     position: "top-right",
            //     showConfirmButton: true,
            //     timer: 3000,
            //   })
            //   .fire({
            //     icon: "error",
            //     title: `${this.phoneSearch} No Product with this serach`,
            //   });
            console.log("error", error);
          });
      }
    },
  }
}
</script>

<style scoped></style>