<template>
  <div>
    <v-autocomplete
      :items="User"
      outlined
      :rounded="rounded"
      dense
      @change="$emit('change', selectedUser)"
      hide-details
      auto-select-first
      :loading="loading"
      label="Select Sale Rep"
      single-line
      item-text="name"
      return-object
      v-model="selectedUser"
      clearable
    >
      <template slot="item" slot-scope="{ item }">
        <span>{{ item.name }}</span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import userService from "@/modules/User/service.js";

export default {
  props: {
    initValue: {
      default: null,
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {
      User: [],
      loading: false,
      selectedUser: null,
    };
  },
  watch: {
    selectedUser(val) {
      if (val) {
        this.$emit("input", val);
        this.$emit("reset");
      }
    },
    initValue(val) {
      // console.log('init for user', val)
      this.selectedUser = val;
    },
  },
  async created() {
    await this.getList();
    if (this.initValue) {
      this.selectedUser = this.initValue;
    }
  },
  methods: {
    getList() {
      this.loading = true;
      return userService.getAll().then((response) => {
        this.loading = false;
        this.User = response.data;
        return response;
      });
    },
  },
};
</script>

<style scoped></style>
