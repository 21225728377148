import backofficeService from '../../api/backoffice'

export default {
  /**
   * Get list of categories
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to categories
   */
  getAll(params) {
    return backofficeService.Api().get('user', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        // console.log('error on user', error)
        throw error
      })
  },

  /**
   * Get category by id
   * @param {*} params Query Options
   */
  getById(id, params) {
    return backofficeService.Api().get(`user/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  create(data) {
    // console.log('here in create', data)
    return backofficeService.Api().post('user', data)
      .then(response => response)
      .catch((error) => {
        // console.log('here in error', error)
        throw error
      })
  },
  /**
   * Update specific category
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`user/${id}`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  changePassword(id, data) {
    return backofficeService.Api().put(`user/changePassword/${id}`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  /**
   * Delete category
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`user/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  /**
   * Get All UserRights
   * @param {*} params
   
   */

  getAllUserRights() {
    return backofficeService.Api().get('userRightCatalog')
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

}
