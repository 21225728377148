<template>
  <v-card outlined color="pink lighten-5">
    <v-card-text>
      <v-layout row wrap align-center justify-space-between fill-height v-if="!remoteOrder">
        <v-flex xs12 sm3>
          <AutoComplete @submit="productSubmitted" ref="selectedProductChild" @emptyEntryBar="emptyEntryBar()"/>
          <!-- <v-autocomplete v-if="Products" v-model="selectedProduct" hide-no-data ref="productSearch" outlined
            prepend-inner-icon="search" rounded auto-select-first dense :loading="loading" single-line hide-details
            clearable id="productSearch" :items="Products" item-text="name" return-object
            :search-input.sync="phoneSearch" :label="$t('labels.searchProduct')">
            <template slot="item" slot-scope="{ item }">
              <div>
                <div>{{ item.name }}</div>
                <small v-if="item.dynamicPrice === item.price" class="red--text">{{ item.dynamicPrice | currency }}
                  *</small>
                <small v-else>{{ item.price | currency }}</small>
              </div>
              <v-spacer></v-spacer>
              <v-chip v-if="item.trackInventory" :color="item.trackSerialNumber
                ? 'light-blue darken-1'
                : 'teal darken-1'
                " dark>
                <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
                  <v-icon small>mdi-barcode-scan</v-icon>
                </v-avatar>
                <v-avatar left v-else class="teal darken-3">
                  <v-icon small>mdi-package-variant-closed</v-icon>
                </v-avatar>
                <span>{{ item.balance }}</span>
              </v-chip>
            </template>

          </v-autocomplete> -->
        </v-flex>
        <v-flex xs12 sm3>
          <v-text-field @focus="$event.target.select()" label="Item Name" :disabled="lockProductName"
            @keyup.enter="$refs.boxQty.focus" v-model="name" outlined dense type="tel" hide-details
            ref="itemName"></v-text-field>
        </v-flex>
        <!-- <v-flex sm1 v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
          <v-text-field class="centered-input" @focus="$event.target.select()" ref="boxQty" label="Box Qty" outlined
            dense type="number" hide-details @keyup.enter="$refs.qty.focus" v-model="boxQty"></v-text-field>
        </v-flex> -->
        <v-flex xs6 sm1>
          <v-text-field class="centered-input" @focus="$event.target.select()" ref="qty" outlined dense hide-details
            label="Quantity" @keyup.enter="$refs.boxPrice.focus" v-model="qty"></v-text-field>
        </v-flex>
        <v-flex xs6 sm2>
          <v-text-field class="right-input" ref="boxPrice" @focus="$event.target.select()" label="Price"
            v-model="price" outlined @keyup.enter="submit" dense hide-details></v-text-field>
        </v-flex>
        <v-flex xs6 sm2>
          <v-text-field disabled class="right-input" ref="extAmount" outlined dense hide-details
            @focus="$event.target.select()" v-model="total" label="Ext Amount"></v-text-field>
        </v-flex>
        <v-flex d-flex xs6 sm1>
          <v-btn color="info" ref="addbutton" large @click="submit">
            add
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap align-center justify-space-between fill-height v-else>
        <v-flex xs12 sm6>
          <!-- <v-autocomplete v-model="selectedProduct" hide-no-data ref="productSearch" outlined
            prepend-inner-icon="search" rounded auto-select-first dense :loading="loading" single-line hide-details
            clearable id="productSearch" :items="Products" item-text="name" return-object
            :search-input.sync="phoneSearch" :label="$t('labels.searchProduct')">
            <template slot="item" slot-scope="{ item }">
              <span>{{ item.name }}</span>
              
            </template>
          </v-autocomplete> -->
        </v-flex>
        <v-flex xs6 sm3>
          <v-text-field class="centered-input" @focus="$event.target.select()" ref="qty" type="tel" outlined dense
            hide-details label="Quantity" v-model="qty"></v-text-field>
        </v-flex>

        <v-flex d-flex xs6 sm3>
          <v-btn color="info" block ref="addbutton" large @click="submit">
            add
          </v-btn>
        </v-flex>
      </v-layout>
      <div v-if="selectedProduct && selectedProduct.unitCountInBox > 0" class="text-center">The box contains <span
          class="font-weight-bold">{{ selectedProduct.unitCountInBox }}</span> Units priced at <span
          class="font-weight-bold">{{
            selectedProduct.price | currency }}</span> each. box price is <span class="font-weight-bold">{{
            selectedProduct.boxPrice | currency }}</span> </div>
    </v-card-text>
  </v-card>
</template>

<script>
// import productService from "@/modules/Product/service";
import orderService from "@/modules/Order/RemoteOrderView/service.js";
import customerPriceService from "@/modules/Customer/CustomerPrice/service.js";
import { mapGetters } from "vuex";
import GlobalFunction from "@/mixins/GlobalFunction";
import AutoComplete from "@/modules/Product/components/AutoComplete.vue";
import _ from 'lodash';

export default {
  props: [
    "selectedCustomer",
    "remoteOrder",
    "business",
    "customerId",
    "customer",
  ],
  data() {
    return {
      loading: false,
      Products: [],
      tempProduct: [],
      phoneSearch: null,
      selectedProduct: null,
      name: null,
      disabledQty: false,
      qty: 1,
      boxQty: 1,
      price: 0,
      total: 0,
      PriceList: [],
    };
  },
  mixins: [GlobalFunction],
  async created() {
    await this.getProduct();
    this.emptyEntryBar();

    await this.getCustomerPriceList();

    this.$events.listen("customerChange", () => this.getCustomerPriceList());
  },
  beforeDestroy() {
    this.$events.remove("customerChange");
  },
  components: {
    AutoComplete
  },
  computed: {
    ...mapGetters("invoice", ["Invoice", "InvoiceDetails"]),
    ...mapGetters("global", ["currentBusiness"]),
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    },
  },
  watch: {
    phoneSearch: _.debounce(function (val) {
      if (val && val.length > 2) {
        this.getProduct();
      }

    }, 300),
    qty(val) {
      console.log('i got call')
      this.total = this.price * val
    },
    // boxQty(val) {
    //   if (this.selectedProduct) {
    //     this.qty = val * this.selectedProduct.unitCountInBox
    //   }

    // },
    price(val) {
      console.log('price watch got call')
      // this.total = val * this.qty;
      // if (this.currentBusiness?.metadata?.boxCountOnInventory) {
      //   if (this.selectedProduct) {
      //     let boxPrice = (this.selectedProduct.unitCountInBox * val).toFixed(2)
      //     boxPrice = parseFloat(boxPrice)
      //     this.total = boxPrice * this.qty;
      //   }
      // } else {
      //   this.total = this.price * this.qty;
      // }
      this.total = this.price * this.qty;
    },
    selectedProduct(val) {
      // console.log('selected price', val)
      if (val) {
        if (val.dynamicPrice) {
          val.price = val.dynamicPrice
        }
        this.setProduct(val);
        this.PriceList.findIndex((price) => {
          // console.log("price", price.ProductId, val.id);
          if (price.ProductId === val.id) {
            // console.log("found price", price);
            this.price = price.price;
          }
        });
      }
    },
  },
  methods: {
    productSubmitted(val) {
       console.log('productSubmitted', val)
       this.selectedProduct = val
    },
    getCustomerPriceList() {
      if (this.remoteOrder) {
        if (this.business) {
          orderService
            .getCustomerPriceList({
              key: this.business.code,
              CustomerId: this.customerId,
            })
            .then((response) => {
              // // console.log("custom price list", response);
              // // console.log("customer", this.customer);
              this.PriceList = response.data;
              if (
                this.customer.metadata &&
                this.customer.metadata.viewOnlyCustomerPrice
              ) {
                // console.log("is on ", this.PriceList.length, this.customerId);
                if (this.PriceList.length !== 0) {
                  // this.Products = [];
                  let localProduct = [];
                  this.PriceList.map((row) => {
                    localProduct.push(row.Product);
                  });
                  // // console.log('localProduct', localProduct)
                  this.Products = localProduct;
                }

                // // console.log('new Products', this.Products)
              }
            });
        }
      } else {
        if (this.selectedCustomer) {
          customerPriceService
            .getAll({ CustomerId: this.selectedCustomer.id })
            .then((response) => {
              // // console.log("price list", response);
              this.PriceList = response.data;
              // // console.log("getCustomerPriceListgetCustomerPriceList", response);
            });
        }
      }
    },
    setProduct(product) {
      if (product) {
        // this.price = product.price;
        // this.total = product.price * this.qty;
        console.log('product adsfasdf', product)
        if (this.currentBusiness?.metadata?.boxCountOnInventory) {
          this.qty = product.unitCountInBox
        }
        this.name = product.name;
        this.price = product.price;
        // this.total = this.price * this.qty;
      


        if (!this.remoteOrder) {
          this.$nextTick(() => {
            // this.$refs.itemName.focus();
            if (this.lockProductName) {
              this.$refs.qty.focus();
            } else {
              this.$refs.itemName.focus();
            }
          });
        } else {
          this.$nextTick(() => {
            this.$refs.qty.focus();
          });
        }
      }
    },
    emptyEntryBar() {
      this.name = null;
      this.qty = 1;
      this.boxQty = 1;
      this.total = 0;
      this.price = 0;
      this.selectedProduct = null;
      setTimeout(() => {
        // this.$refs.productSearch.focus();
        this.$refs.selectedProductChild.$refs.productSearchInput.focus();
      }, 300);
      if (this.$refs?.selectedProductChild) {
        this.$refs.selectedProductChild.emptyAutoComplete()
      }6
    },
    submit() {
      if (!this.selectedProduct) return false;
      this.selectedProduct.Bar = {
        name: this.name,
        qty: this.qty,
        price: this.price,
        boxQty: this.boxQty,
      };

      this.$emit("submit", this.selectedProduct);
      this.emptyEntryBar();
    },
    getProduct() {
      this.loading = true;

      if (this.remoteOrder) {
        return orderService
          .getPublicProduct({ key: this.business.code })
          .then((result) => {
            this.Products = result.data;
            this.loading = false;
          });
      } else {
        return orderService.getProduct({ search: this.phoneSearch, hideParent: true }).then((result) => {
          // console.log('get prodcut', result)
          this.Products = result.data;
          if (this.currentBusiness.metadata && this.currentBusiness.metadata.dynamicPrice) {
            this.Products.map(row => {
              row.dynamicPrice = this.getDynamicPrice(row, this.currentBusiness.metadata.dynamicPriceList)
            })
          }
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped></style>
