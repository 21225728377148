<template>
  <div>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <v-autocomplete
    :disabled="disabled"
     :items="Customer" outlined @change="$emit('change', selectedCustomer)"
      :error-messages="errorMessages" rounded :hide-details="errorMessages.length === 0 ? true : false"
      prepend-inner-icon="search" dense :loading="loading" auto-select-first label="Select Customer" single-line
      :search-input.sync="customerInput" clearable item-text="name" return-object v-model="selectedCustomer">
      <template slot="item" slot-scope="{ item }">
        <span>{{ item.name }}</span>
        <v-spacer></v-spacer>
        <span>{{ item.balance | currency }}</span>
      </template>
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item @click="createDialog = true">
          <v-list-item-content>
            <v-list-item-title>Create New Customer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import customerService from "@/modules/Customer/service";
const Create = () => import("@/modules/Customer/components/Create");
import { mapGetters } from "vuex";

export default {
  props: {
    disabled: {
      default: false,
    },
    errorMessages: {
      default: function () {
        return [];
      }
    },
    initValue: {
      default: null
    }
  },
  data() {
    return {
      createDialog: false,
      customerInput: null,
      Customer: [],
      loading: false,
      selectedCustomer: null
    };
  },
  components: {
    Create
  },
  watch: {
    selectedCustomer(val) {
      if (val) {
        this.$emit("input", val);
        this.$events.emit("customerChange", val);
        this.$emit("reset");
      }
    },
    initValue(val) {
      this.selectedCustomer = val
    }
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let where = {};
      if(!this.currentUser.isAdmin && this.checkRightStatus(50)){
        where.SalesRepUserId = this.currentUser.id
      }
      this.loading = true;
      return customerService.getAll(where).then(response => {
        this.loading = false;
        this.Customer = response.data;
        if (this.$route.query && this.$route.query.CustomerId) {
          const _customerId = this.initValue
          const result = this.Customer.find(({ id }) => id == _customerId);
          this.selectedCustomer = result
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
