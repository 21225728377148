<template>
  <div>
    <v-app-bar elevation="0" :color="backgroundColor" :dark="darkMode" :app="public">
      <v-toolbar-title v-if="business">
        <v-list-item-title class="font-weight-bold">
          <span v-if="business && business.metadata && business.metadata.remoteOrderBusinessName">{{ business.name
            }}</span>
          <span v-else>Available Inventory</span>
        </v-list-item-title>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn v-if="!orderCreated && public" color="pink" small :disabled="orderTotal().total === 0"
        @click="sendOrder = !sendOrder">{{
          orderTotal().total | currency }} Save</v-btn>

      <v-btn color="pink" v-if="!public" small @click="BackToOrder">Back To Order Create</v-btn>

      <v-dialog v-model="sendOrder" max-width="300">

        <v-card>
          <ModelTitle title="Send Order" @close="sendOrder = !sendOrder" />
          <v-container grid-list-lg fluid>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field v-model="name" :error-messages="nameErrors" :hide-details="nameErrors.length === 0"
                  outlined dense autofocus label="Business Name" ref="name"></v-text-field>

              </v-flex>
              <v-flex xs12>
                <v-text-field type="tel" v-model="phoneNumber" :error-messages="phoneNumberErrors"
                  :hide-details="phoneNumberErrors.length === 0" outlined dense label="Phone Number"
                  ref="phoneNumber"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="saveOrder">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <template v-slot:extension v-if="!orderCreated && business && business.metadata.remoteOrderCategory">
        <v-tabs v-if="Categories.length" v-model="tab" align-with-title>
          <v-tabs-slider color="red"></v-tabs-slider>

          <v-tab v-for="category in Categories" :value="category.id" @change="tabChange(category)" :key="category.id">
            {{ category.name }}
          </v-tab>
        </v-tabs>
      </template>

    </v-app-bar>


    <v-main v-if="public">
      <v-container grid-list-lg fluid v-if="OpenOrder.length !== 0">
        <v-card outlined>
          <v-card-text>
            <v-btn :color="backgroundColor" @click="newOrder()">New Order</v-btn>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container fluid grid-list-lg v-else>

        <v-dialog v-model="imageDialog" width="500" v-if="selectedProduct">
          <v-card>
            <ModelTitle :title="selectedProduct.name" @close="imageDialog = !imageDialog" />



            <v-container grid-list-lg>
              <v-img v-if="selectedProduct.imageUrl" :lazy-src="selectedProduct.imageUrl" contain max-height="500"
                :src="selectedProduct.imageUrl"></v-img>
            </v-container>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeImageDialog">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-layout column v-if="!orderCreated">

          <v-flex xs12 sm4 class="text-right">
            <v-layout row>
              <v-flex shrink v-if="!$vuetify.breakpoint.xsOnly">
                <v-btn-toggle v-model="listType" color="deep-purple accent-3">
                  <v-btn outlined>
                    <v-icon>mdi-border-all</v-icon>
                  </v-btn>

                  <v-btn outlined>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>

                </v-btn-toggle>
              </v-flex>
              <v-flex>
                <v-text-field v-model="search" outlined prepend-inner-icon="search" clearable rounded autofocus dense
                  label="Search" single-line hide-details></v-text-field>
              </v-flex>

              <v-flex shrink>
                <v-dialog v-model="menu" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <v-icon>mdi-filter-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <ModelTitle title="Filter" @close="menu = !menu" />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-layout row align-center>
                            <v-flex class="pl-3">
                              Filter By Category
                            </v-flex>
                            <v-flex shrink class="pr-3">
                              <v-switch v-model="categorySearch" inset></v-switch>
                            </v-flex>
                          </v-layout>

                          <v-select v-if="categorySearch" :items="Categories" hide-details item-text="name"
                            v-model="selectedCategory" item-value="id" label="Category" dense outlined></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-layout row align-center>
                            <v-flex class="pl-3">
                              Filter By Range
                            </v-flex>
                            <v-flex shrink class="pr-3">
                              <v-switch v-model="rangeSearch" inset></v-switch>
                            </v-flex>
                          </v-layout>
                        </v-col>
                        <v-col class="px-4" v-if="rangeSearch">
                          <v-range-slider v-model="range" :max="max" :min="min" hide-details class="align-center">
                            <template v-slot:prepend>
                              <v-text-field dense outlined :value="range[0]" class="mt-0 pt-0" hide-details single-line
                                type="number" style="width: 60px" @change="$set(range, 0, $event)"></v-text-field>
                            </template>
                            <template v-slot:append>
                              <v-text-field dense outlined :value="range[1]" class="mt-0 pt-0" hide-details single-line
                                type="number" style="width: 60px" @change="$set(range, 1, $event)"></v-text-field>
                            </template>
                          </v-range-slider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="submitFilter">
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex>

            <v-data-table :fixed-header="true" :headers="headers" :items="Products" :loading="loading" :sort-by="sortBy"
              :page.sync="page" :items-per-page="itemsPerPage" v-if="listType === 1">
              <template v-slot:item="{ item }">
                <tr class="pointer">
                  <td>
                    <v-avatar tile>
                      <!-- <img v-if="imageUrl" :src="item.imageUrl" alt="Item"> -->
                      <v-img v-if="item.imageUrl" :lazy-src="item.imageUrl" max-height="150" max-width="250"
                        @click="openImageDialog(item)" :src="item.imageUrl"></v-img>

                      <v-img v-else lazy-src="@/assets/images/photoComingSoon.png" max-height="150" max-width="250"
                        src="@/assets/images/photoComingSoon.png"></v-img>
                      <!-- <v-img v-else src="@/assets/images/photoComingSoon.png" alt="Item"> -->
                    </v-avatar>
                  </td>

                  <!-- edited by Muhammad . To show full name of the product on the search results  -->
                  <td class="text-left">
                    <div>
                      <span class="d-inline-block font-weight-medium" style="max-width: 600px; white-space: normal;"
                        v-html="$highlight(
                          item.name,
                          search
                        )
                          ">{{ item.name }}</span>

                    </div>
                    <div class="caption" v-if="business && business.metadata && business.metadata.boxCountOnInventory">
                      The box contains
                      <span class="font-weight-bold">{{ item.unitCountInBox }}</span> priced at <span
                        class="font-weight-bold">{{
                          item.price | currency }}</span> a box
                    </div>
                  </td>
                  <td>
                    <span class="d-inline-block text-truncate" style="max-width: 200px;" v-html="$highlight(
                      item.Category.name,
                      search
                    )
                      ">{{ item.Category.name }}</span>
                  </td>
                  <td class="text-center" v-html="$highlight(item.balance, search)
                    ">
                    <div>{{ item.balance }}</div>

                  </td>

                  <td class="text-right">
                    <div v-if="item.dynamicPrice === item.price" class="red--text">{{ item.dynamicPrice |
                      currency }} *</div>
                    <!-- <div v-else-if="item.price">{{ item.price | currency }}</div> -->
                    <div v-else>
                      <span v-if="business && business.metadata && business.metadata.boxCountOnInventory">{{
                        item.price
                        / item.unitCountInBox | currency }}</span>
                      <span v-else>{{ item.price | currency }}</span>
                    </div>
                  </td>

                  <td class="text-right">
                    <v-btn text icon @click="openAdd(item)">
                      <v-icon>mdi-cart-plus</v-icon>
                    </v-btn>

                  </td>
                </tr>
              </template>

            </v-data-table>


            <div v-else>
              <v-row dense v-if="groupedProducts.length !== 1">
                <v-col sm="12" v-for="department in groupedProducts">
                  <v-chip class="my-2 font-weight-bold" label color="#0AC1D3" text-color="white">{{
                    department.Products.length }} - {{
                      department.departmentName
                    }}</v-chip>
                  <v-row>
                    <v-col lg="3" sm="6" md="4" v-for="(product, index) in department.Products" :key="index">
                      <v-card :loading="loading" class="pointer mx-auto my-4 elevation-3 rounded-xl position-relative"
                        outlined max-width="374">
                        <template #progress>
                          <v-progress-linear color="deep-purple" height="8" indeterminate></v-progress-linear>
                        </template>

                        <!-- Special Price Ribbon -->
                        <!-- <div v-if="product.special" class="special-ribbon">
                          🔥 Special Price
                        </div> -->
                        <v-card v-if="product.special" color="red darken-2" class="mb-2">
                          <v-card-text class="py-2">
                            <div class="white--text font-weight-black subtitle-1" >🔥 Special Price</div>
                          </v-card-text>
                        </v-card>

                        <!-- Product Image -->
                        <v-img v-if="product.imageUrl" :src="product.imageUrl" :lazy-src="product.imageUrl" height="220"
                          contain class="rounded-t-xl"></v-img>
                        <v-img v-else src="@/assets/images/photoComingSoon.png"
                          lazy-src="@/assets/images/photoComingSoon.png" height="220" contain
                          class="rounded-t-xl"></v-img>

                        <!-- Product Details -->
                        <v-card-title class="pb-1 font-weight-bold title-scroll">
                          {{ product.name }}
                        </v-card-title>

                        <v-card-text class="pt-0 pb-3">
                          <v-row class="align-center">
                            <v-col cols="6">
                              <div v-if="product.dynamicPrice === product.price">
                                <span class="font-weight-bold text-red">{{ product.dynamicPrice | currency }} *</span>
                              </div>
                              <div v-else-if="product.unitCountInBox && product.unitCountInBox > 0">
                                <span class="font-weight-medium">Per Unit: </span>
                                <span class="font-weight-bold">{{ product.price / product.unitCountInBox | currency
                                  }}</span>
                              </div>
                              <div v-else>
                                <span class="font-weight-medium">Price: </span>
                                <span class="font-weight-bold">{{ product.price | currency }}</span>
                              </div>
                            </v-col>

                            <v-col cols="6" class="text-right">
                              <div class="text-caption text-grey-darken-2">Stock:
                                <span class="font-weight-bold">{{ product.balance }}</span>
                              </div>
                              <div v-if="product.unitCountInBox" class="text-caption text-grey-darken-1">
                                {{ product.unitCountInBox }} per box
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider class="mx-4"></v-divider>

                        <!-- Action Buttons (BIGGER + MORE VISIBLE) -->
                        <v-card-actions class="justify-center py-3">
                          <!-- Minus Button -->
                          <v-btn v-if="product.orderQuantity" color="red darken-1" fab x-large
                            :disabled="product.orderQuantity == 0" @click="minus(product)">
                            <v-icon size="30">mdi-minus</v-icon>
                          </v-btn>
                          <v-btn v-else disabled x-large fab>
                            <v-icon size="30">mdi-minus</v-icon>
                          </v-btn>

                          <!-- Quantity Display -->
                          <h2 class="mx-4 font-weight-bold text-h4">{{ product.orderQuantity }}</h2>

                          <!-- Plus Button -->
                          <v-btn :color="backgroundColor" :dark="darkMode" fab x-large
                            :disabled="product.orderQuantity >= product.balance" @click="add(product)">
                            <v-icon size="30">mdi-plus</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>


              </v-row>
              <v-row v-else>
                <v-col lg="3" sm="6" md="4" v-for="(product, index) in Products" :key="index">
                  <v-card :loading="loading" class="pointer mx-auto my-4 elevation-3 rounded-xl position-relative"
                        outlined max-width="374">
                        <template #progress>
                          <v-progress-linear color="deep-purple" height="8" indeterminate></v-progress-linear>
                        </template>
                        
                        <v-card v-if="product.special" color="red darken-2" class="mb-2">
                          <v-card-text class="py-2">
                            <div class="white--text font-weight-black subtitle-1" >🔥 Special Price</div>
                          </v-card-text>
                        </v-card>

                        <!-- Product Image -->
                        <v-img v-if="product.imageUrl" :src="product.imageUrl" :lazy-src="product.imageUrl" height="220"
                          contain class="rounded-t-xl"></v-img>
                        <v-img v-else src="@/assets/images/photoComingSoon.png"
                          lazy-src="@/assets/images/photoComingSoon.png" height="220" contain
                          class="rounded-t-xl"></v-img>

                        <!-- Product Details -->
                        <v-card-title class="pb-1 font-weight-bold title-scroll">
                          {{ product.name }}
                        </v-card-title>

                        <v-card-text class="pt-0 pb-3">
                          <v-row class="align-center">
                            <v-col cols="6">
                              <div v-if="product.dynamicPrice === product.price">
                                <span class="font-weight-bold text-red">{{ product.dynamicPrice | currency }} *</span>
                              </div>
                              <div v-else-if="product.unitCountInBox && product.unitCountInBox > 0">
                                <span class="font-weight-medium">Per Unit: </span>
                                <span class="font-weight-bold">{{ product.price / product.unitCountInBox | currency
                                  }}</span>
                              </div>
                              <div v-else>
                                <span class="font-weight-medium">Price: </span>
                                <span class="font-weight-bold">{{ product.price | currency }}</span>
                              </div>
                            </v-col>

                            <v-col cols="6" class="text-right">
                              <div class="text-caption text-grey-darken-2">Stock:
                                <span class="font-weight-bold">{{ product.balance }}</span>
                              </div>
                              <div v-if="product.unitCountInBox" class="text-caption text-grey-darken-1">
                                {{ product.unitCountInBox }} per box
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider class="mx-4"></v-divider>

                        <!-- Action Buttons (BIGGER + MORE VISIBLE) -->
                        <v-card-actions class="justify-center py-3">
                          <!-- Minus Button -->
                          <v-btn v-if="product.orderQuantity" color="red darken-1" fab x-large
                            :disabled="product.orderQuantity == 0" @click="minus(product)">
                            <v-icon size="30">mdi-minus</v-icon>
                          </v-btn>
                          <v-btn v-else disabled x-large fab>
                            <v-icon size="30">mdi-minus</v-icon>
                          </v-btn>

                          <!-- Quantity Display -->
                          <h2 class="mx-4 font-weight-bold text-h4">{{ product.orderQuantity }}</h2>

                          <!-- Plus Button -->
                          <v-btn :color="backgroundColor" :dark="darkMode" fab x-large
                            :disabled="product.orderQuantity >= product.balance" @click="add(product)">
                            <v-icon size="30">mdi-plus</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                </v-col>

              </v-row>
            </div>


          </v-flex>



        </v-layout>

        <v-card v-else flat>

          <v-card-text>
            <lottie :height="200" :options="defaultOptions" name="receiptCheck" v-on:animCreated="handleAnimation" />
            <div class="text-center">
              <div class="text-h5">All Set</div>
              <div class="mt-3">Your order was submitted</div>
            </div>
            <div class="text-center">
              <v-btn class="mt-5" :color="backgroundColor" @click="newOrder">New Order</v-btn>
            </div>

          </v-card-text>
        </v-card>
      </v-container>
    </v-main>


    <div v-else>
      <v-container grid-list-lg fluid v-if="OpenOrder.length !== 0">
        <v-card outlined>
          <v-card-text>
            <v-btn :color="backgroundColor" @click="newOrder()">New Order</v-btn>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container fluid grid-list-lg v-else>

        <v-dialog v-model="imageDialog" width="500" v-if="selectedProduct">
          <v-card>
            <ModelTitle :title="selectedProduct.name" @close="imageDialog = !imageDialog" />



            <v-container grid-list-lg>
              <v-img v-if="selectedProduct.imageUrl" :lazy-src="selectedProduct.imageUrl" contain max-height="500"
                :src="selectedProduct.imageUrl"></v-img>
            </v-container>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeImageDialog">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-layout column v-if="!orderCreated">

          <v-flex xs12 sm4 class="text-right">
            <v-layout row>
              <v-flex shrink v-if="!$vuetify.breakpoint.xsOnly">
                <v-btn-toggle v-model="listType" color="deep-purple accent-3">
                  <v-btn outlined>
                    <v-icon>mdi-border-all</v-icon>
                  </v-btn>

                  <v-btn outlined>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>

                </v-btn-toggle>
              </v-flex>
              <v-flex>
                <v-text-field v-model="search" outlined prepend-inner-icon="search" clearable rounded autofocus dense
                  label="Search" single-line hide-details></v-text-field>
              </v-flex>

              <v-flex shrink>
                <v-dialog v-model="menu" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <v-icon>mdi-filter-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <ModelTitle title="Filter" @close="menu = !menu" />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-layout row align-center>
                            <v-flex class="pl-3">
                              Filter By Category
                            </v-flex>
                            <v-flex shrink class="pr-3">
                              <v-switch v-model="categorySearch" inset></v-switch>
                            </v-flex>
                          </v-layout>

                          <v-select v-if="categorySearch" :items="Categories" hide-details item-text="name"
                            v-model="selectedCategory" item-value="id" label="Category" dense outlined></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-layout row align-center>
                            <v-flex class="pl-3">
                              Filter By Range
                            </v-flex>
                            <v-flex shrink class="pr-3">
                              <v-switch v-model="rangeSearch" inset></v-switch>
                            </v-flex>
                          </v-layout>
                        </v-col>
                        <v-col class="px-4" v-if="rangeSearch">
                          <v-range-slider v-model="range" :max="max" :min="min" hide-details class="align-center">
                            <template v-slot:prepend>
                              <v-text-field dense outlined :value="range[0]" class="mt-0 pt-0" hide-details single-line
                                type="number" style="width: 60px" @change="$set(range, 0, $event)"></v-text-field>
                            </template>
                            <template v-slot:append>
                              <v-text-field dense outlined :value="range[1]" class="mt-0 pt-0" hide-details single-line
                                type="number" style="width: 60px" @change="$set(range, 1, $event)"></v-text-field>
                            </template>
                          </v-range-slider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="submitFilter">
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-flex>
            </v-layout>

          </v-flex>

          <v-flex>




            <v-data-table :fixed-header="true" :headers="headers" :items="Products" :loading="loading" :sort-by="sortBy"
              :page.sync="page" :items-per-page="itemsPerPage" v-if="listType === 1">
              <template v-slot:item="{ item }">
                <tr class="pointer">
                  <td>
                    <v-avatar tile>
                      <!-- <img v-if="imageUrl" :src="item.imageUrl" alt="Item"> -->
                      <v-img v-if="item.imageUrl" :lazy-src="item.imageUrl" max-height="150" max-width="250"
                        @click="openImageDialog(item)" :src="item.imageUrl"></v-img>

                      <v-img v-else lazy-src="@/assets/images/photoComingSoon.png" max-height="150" max-width="250"
                        src="@/assets/images/photoComingSoon.png"></v-img>
                      <!-- <v-img v-else src="@/assets/images/photoComingSoon.png" alt="Item"> -->
                    </v-avatar>
                  </td>

                  <!-- edited by Muhammad . To show full name of the product on the search results  -->
                  <td class="text-left">
                    <div>
                      <span class="d-inline-block font-weight-medium" style="max-width: 600px; white-space: normal;"
                        v-html="$highlight(
                          item.name,
                          search
                        )
                          ">{{ item.name }}</span>

                    </div>
                    <div class="caption" v-if="business && business.metadata && business.metadata.boxCountOnInventory">
                      The box contains
                      <span class="font-weight-bold">{{ item.unitCountInBox }}</span> priced at <span
                        class="font-weight-bold">{{
                          item.price | currency }}</span> a box
                    </div>
                  </td>
                  <td>
                    <span class="d-inline-block text-truncate" style="max-width: 200px;" v-html="$highlight(
                      item.Category.name,
                      search
                    )
                      ">{{ item.Category.name }}</span>
                  </td>
                  <td class="text-center" v-html="$highlight(item.balance, search)
                    ">
                    <div>{{ item.balance }}</div>

                  </td>

                  <td class="text-right">
                    <div v-if="item.dynamicPrice === item.price" class="red--text">{{ item.dynamicPrice |
                      currency }} *</div>
                    <!-- <div v-else-if="item.price">{{ item.price | currency }}</div> -->
                    <div v-else>
                      <span v-if="business && business.metadata && business.metadata.boxCountOnInventory">{{
                        item.price
                        / item.unitCountInBox | currency }}</span>
                      <span v-else>{{ item.price | currency }}</span>
                    </div>
                  </td>

                  <td class="text-right">
                    <v-btn text icon @click="openAdd(item)">
                      <v-icon>mdi-cart-plus</v-icon>
                    </v-btn>

                  </td>
                </tr>
              </template>

            </v-data-table>


            <div v-else>
              <v-row dense v-if="groupedProducts.length !== 1">
                <v-col sm="12" v-for="department in groupedProducts">
                  <v-chip class="my-2 font-weight-bold" label color="#0AC1D3" text-color="white">{{
                    department.Products.length }} - {{
                      department.departmentName
                    }}</v-chip>
                  <v-row>
                    <v-col lg="3" sm="6" md="4" v-for="(product, index) in department.Products" :key="index">
                      <v-card :loading="loading" class="pointer mx-auto my-4" outlined max-width="374">
                        <template #progress>
                          <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                        </template>


                        <v-img v-if="product.imageUrl" :src="product.imageUrl" :lazy-src="product.imageUrl" height="250"
                          contain></v-img>
                        <v-img v-else src="@/assets/images/photoComingSoon.png"
                          lazy-src="@/assets/images/photoComingSoon.png" height="250" contain></v-img>


                        <v-card-title class="pb-0 title-scroll">
                          {{ product.name }}
                        </v-card-title>


                        <v-card-text class="pt-0">
                          <div class="my-4 text-subtitle-1">
                            <div v-if="product.dynamicPrice === product.price">
                              Price: <span class="font-weight-bold red--text">{{ product.dynamicPrice | currency
                                }}
                                *</span>
                            </div>
                            <div v-else-if="product.unitCountInBox && product.unitCountInBox > 0">
                              Price per unit: <span class="font-weight-bold">{{ product.price /
                                product.unitCountInBox |
                                currency }}</span>
                            </div>
                            <div v-else>
                              Price: <span class="font-weight-bold">{{ product.price | currency }}</span>
                            </div>
                            <div v-if="product.unitCountInBox && product.unitCountInBox > 0">
                              Box Price: <span class="font-weight-bold">{{ product.price | currency }}</span>
                            </div>
                          </div>
                        </v-card-text>

                        <v-divider class="mx-4"></v-divider>


                        <v-card-title>
                          <v-row>
                            <v-col>
                              <div>Stock: <span class="font-weight-bold">{{ product.balance }}</span></div>
                            </v-col>
                            <v-col>
                              <div class="text-subtitle-1" v-if="product.unitCountInBox">
                                {{ product.unitCountInBox }} in a box
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>


                        <v-card-actions>
                          <v-row>
                            <v-col>
                              <v-btn v-if="product.orderQuantity" color="red lighten-1" fab
                                :disabled="product.orderQuantity == 0" small @click="minus(product)">
                                <v-icon>
                                  mdi mdi-minus
                                </v-icon>
                              </v-btn>
                              <v-btn v-else disabled small fab>
                                <v-icon dark>
                                  mdi mdi-minus
                                </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col>
                              <h1>{{ product.orderQuantity }}</h1>

                            </v-col>
                            <v-col>
                              <v-btn :color="backgroundColor" :dark="darkMode" fab
                                :disabled="product.orderQuantity >= product.balance" small @click="add(product)">
                                <v-icon :dark="darkMode">
                                  mdi mdi-plus
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>


                        </v-card-actions>

                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>


              </v-row>
              <v-row v-else>
                <v-col lg="3" sm="6" md="4" v-for="(product, index) in Products" :key="index">
                  <v-card :loading="loading" class="pointer mx-auto my-4" outlined max-width="374">
                    <template #progress>
                      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                    </template>


                    <v-img v-if="product.imageUrl" :src="product.imageUrl" :lazy-src="product.imageUrl" height="250"
                      contain></v-img>
                    <v-img v-else src="@/assets/images/photoComingSoon.png"
                      lazy-src="@/assets/images/photoComingSoon.png" height="250" contain></v-img>


                    <v-card-title class="pb-0 title-scroll">
                      {{ product.name }}
                    </v-card-title>


                    <v-card-text class="pt-0">
                      <div class="my-4 text-subtitle-1">
                        <div v-if="product.dynamicPrice === product.price">
                          Price: <span class="font-weight-bold red--text">{{ product.dynamicPrice | currency
                            }}
                            *</span>
                        </div>
                        <div v-else-if="product.unitCountInBox && product.unitCountInBox > 0">
                          Price per unit: <span class="font-weight-bold">{{ product.price /
                            product.unitCountInBox |
                            currency }}</span>
                        </div>
                        <div v-else>
                          Price: <span class="font-weight-bold">{{ product.price | currency }}</span>
                        </div>
                        <div v-if="product.unitCountInBox && product.unitCountInBox > 0">
                          Box Price: <span class="font-weight-bold">{{ product.price | currency }}</span>
                        </div>
                      </div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>


                    <v-card-title>
                      <v-row>
                        <v-col>
                          <div>Stock: <span class="font-weight-bold">{{ product.balance }}</span></div>
                        </v-col>
                        <v-col>
                          <div class="text-subtitle-1" v-if="product.unitCountInBox">
                            {{ product.unitCountInBox }} in a box
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>


                    <v-card-actions>
                      <v-row>
                        <v-col>
                          <v-btn v-if="product.orderQuantity" color="red lighten-1" fab
                            :disabled="product.orderQuantity == 0" small @click="minus(product)">
                            <v-icon>
                              mdi mdi-minus
                            </v-icon>
                          </v-btn>
                          <v-btn v-else disabled small fab>
                            <v-icon dark>
                              mdi mdi-minus
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <h1>{{ product.orderQuantity }}</h1>

                        </v-col>
                        <v-col>
                          <v-btn :color="backgroundColor" :dark="darkMode" fab
                            :disabled="product.orderQuantity >= product.balance" small @click="add(product)">
                            <v-icon :dark="darkMode">
                              mdi mdi-plus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>


                    </v-card-actions>

                  </v-card>
                </v-col>

              </v-row>
            </div>

          </v-flex>



        </v-layout>

        <v-card v-else flat>

          <v-card-text>
            <lottie :height="200" :options="defaultOptions" name="receiptCheck" v-on:animCreated="handleAnimation" />
            <div class="text-center">
              <div class="text-h5">All Set</div>
              <div class="mt-3">Your order was submitted</div>
            </div>
            <div class="text-center">
              <v-btn class="mt-5" :color="backgroundColor" @click="newOrder">New Order</v-btn>
            </div>

          </v-card-text>
        </v-card>
      </v-container>
    </div>

    <v-footer class="pa-0" app v-if="Cart.length">
      <v-card width="100%">
        <v-container grid-list-lg>
          <v-layout row wrap justify-space-between align-center>
            <v-flex>
              <v-btn text @click="reset">Reset</v-btn>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex class="text-right">

              <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-badge bordered color="error" :content="orderTotal().count" overlap left>
                    <v-btn v-bind="attrs" v-on="on" class="white--text" color="primary" depressed>
                      View Cart
                    </v-btn>
                  </v-badge>
                  <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Open Dialog
                  </v-btn> -->
                </template>
                <v-card tile>
                  <v-toolbar :color="backgroundColor" :dark="darkMode">
                    <v-btn icon :dark="darkMode" @click="dialog = !dialog">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Cart</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn text :dark="darkMode" v-if="public" @click="sendOrder = !sendOrder">
                        {{ orderTotal().total | currency }} Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-list>
                    <v-subheader>Order</v-subheader>
                    <template v-for="(item, index) in Cart">
                      <v-list-item @click="openAdd(item, true)" :key="index">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.orderCount }}x {{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-title>{{ item.totalAmount | currency }}</v-list-item-title>
                        </v-list-item-action>
                        <v-list-item-action>
                          <!-- Delete Button -->
                          <v-btn icon @click.stop="deleteItem(index, item)">
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-list>
                  <!-- <v-list>
                    <v-subheader>Order</v-subheader>
                    <template v-for="(item, index) in Cart">
                      <v-list-item :key="index" @click="openAdd(item, true)">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.orderCount }}x {{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          <v-list-item-title>{{ item.totalAmount | currency }}</v-list-item-title>
                        </v-list-item-action-text>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-list> -->
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-footer>

    <v-dialog v-model="addCartDialog" width="500" v-if="selectedProduct">
      <v-card>
        <ModelTitle :title="selectedProduct.name" @close="closeCartDialog()" />
        <!-- <v-card-title class="text-h6 grey lighten-2">
          <span class="d-inline-block text-truncate font-weight-medium" style="max-width: 400px;">{{
            selectedProduct.name }}</span>
        </v-card-title> -->
        <v-container grid-list-md>
          <v-layout row wrap class="mb-2">
            <v-flex>
              <div>Stock: <span class="font-weight-bold">{{ selectedProduct.balance }}</span></div>
              <div v-if="selectedProduct.unitCountInBox"><span class="font-weight-bold">{{
                selectedProduct.unitCountInBox }}
                </span> In a box</div>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex class="text-right">
              <div v-if="selectedProduct.dynamicPrice === selectedProduct.price">Price: <span
                  class="font-weight-bold red--text">{{ selectedProduct.dynamicPrice | currency }} *</span></div>
              <div v-else-if="selectedProduct.unitCountInBox && selectedProduct.unitCountInBox > 0">Price: <span
                  class="font-weight-bold">{{ selectedProduct.price / selectedProduct.unitCountInBox | currency
                  }}</span>
              </div>
              <div v-else>Price: <span class="font-weight-bold">{{ selectedProduct.price | currency }}</span></div>
              <div v-if="selectedProduct.unitCountInBox && selectedProduct.unitCountInBox > 0">Box Price: <span
                  class="font-weight-bold">{{ selectedProduct.price | currency }}</span>
              </div>
            </v-flex>
          </v-layout>
          <div class="text-center">
            <v-img v-if="selectedProduct.imageUrl" contain :lazy-src="selectedProduct.imageUrl" max-height="160"
              :src="selectedProduct.imageUrl"></v-img>
            <v-img v-else lazy-src="@/assets/images/photoComingSoon.png" contain max-height="150"
              src="@/assets/images/photoComingSoon.png"></v-img>
            <v-layout row align-center justify-space-around class="mt-3">
              <v-flex shrink>
                <v-btn v-if="selectedProduct.orderQuantity" color="red lighten-1" fab
                  :disabled="selectedProduct.orderQuantity == 0" small @click="minusInOrderCart(selectedProduct)">
                  <v-icon>
                    mdi mdi-minus
                  </v-icon>
                </v-btn>
                <v-btn v-else disabled small fab>
                  <v-icon dark>
                    mdi mdi-minus
                  </v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-model="selectedProduct.orderQuantity" outlined rounded dense label="Qty" single-line
                  hide-details></v-text-field>
              </v-flex>
              <!-- <v-flex shrink class="title" v-if="selectedProduct.orderCount">{{ selectedProduct.orderCount }}</v-flex>
              <v-flex shrink class="title" v-else>0</v-flex> -->
              <v-flex shrink>
                <v-btn :color="backgroundColor" :dark="darkMode" fab
                  :disabled="selectedProduct.orderQuantity >= selectedProduct.balance" small @click="addInOrderCart()">
                  <v-icon :dark="darkMode">
                    mdi mdi-plus
                  </v-icon>
                </v-btn>

              </v-flex>
            </v-layout>
          </div>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="addCartDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="backgroundColor" :dark="darkMode" @click="submitQtyFromCart">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");
// import DateSelector from "@/components/DateSelector";
// import AutoComplete from "@/modules/Customer/components/AutoComplete";
import ProductSearchBar from "@/modules/Order/InHouse/components/ProductSearchBar";
import orderService from "@/modules/Order/RemoteOrderView/service.js";
import productService from "@/modules/Product/service.js";
import categoryService from "@/modules/Product/Category/service.js";
import Hashids from "hashids";
const hashids = new Hashids();
import { mapGetters } from "vuex";
import Lottie from "@/components/lottie.vue";
import * as animationData from "@/assets/lottie/1708-success.json";
import _ from 'lodash';
import copy from "copy-to-clipboard";
import GlobalFunction from "@/mixins/GlobalFunction";

export default {
  name: "create-remote-order",
  props: {
    public: {
      default: true
    },
  },
  data() {
    return {
      groupedProducts: [],
      listType: 0,
      rangeSearch: false,
      categorySearch: false,
      selectedCategory: null,
      min: 0,
      max: 2000,
      range: [0, 2000],
      menu: null,
      dialog: false,
      addCartDialog: false,
      Cart: [],
      tab: null,
      Categories: [],
      selectedProduct: null,
      imageDialog: false,
      sortBy: "name",
      search: "",
      Products: [],
      model: [],
      productId: null,
      editOrder: false,
      code: null,
      business: null,
      OpenOrder: [],
      sendOrder: false,
      customerId: null,
      selectedCustomer: null,
      Customer: null,
      loading: true,
      GroupProduct: [],
      name: null,
      phoneNumber: null,
      Order: {
        date: this.$moment().toISOString(),
        note: null,
        OrderDetails: [],
      },
      defaultOptions: {
        animationData: animationData.default,
        loop: false,
        autoplay: true,
      },
      orderCreated: false,
      headers: [
        {
          text: "Image",
          align: "left",
          value: "image",
          sortable: false,
          width: '65'
        },
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Category",
          align: "left",
          value: "Category.name",
          sortable: true,
        },
        {
          text: "Stock",
          align: "center",
          value: "balance",
          width: "100",
          sortable: true,
        },
        {
          text: "Price",
          align: "right",
          value: "price",
          sortable: true,
        },
        {
          text: "Action",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      count: 0,
      options: {
        page: 1,
        itemsPerPage: 2000,
        sortBy: ['name'],
        sortDesc: [false]
      },
      page: 1,
      sortBy: "name",
      pageCount: 0,
      itemsPerPage: 10,
      // sortDesc: false, // Whether to sort in descending order
      // sortBy: 'name', 
      // pagination: {}, // Default sorting column
      limit: 2000,
      offset: 0,
      rowsPerPageItems: [10, 15, 50, 100],
    };
  },
  components: {
    // DateSelector,
    // AutoComplete,
    ProductSearchBar,
    Lottie,
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    name: {
      required,
    },
    phoneNumber: {
      required,
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    backgroundColor() {
      if (!this.business?.metadata?.remoteOrderBackgroundColor) return '#F0C316'

      return this.business.metadata.remoteOrderBackgroundColor
    },
    darkMode() {
      if (!this.business?.metadata?.remoteOrderDarkMode) return false

      return this.business.metadata.remoteOrderDarkMode
    },
    numberOfPages() {
      let value = 0
      if (this.count > 0) {
        value = this.count / this.options.itemsPerPage
      }

      return value
    },
    filteredProducts() {
      if (!this.search) {
        return this.Products; // Return all products if search query is empty
      }

      const searchTerms = this.search.toLowerCase().split(' ');

      return this.Products.filter(product => {
        return searchTerms.every(term =>
          product.name.toLowerCase().includes(term)
        );
      });
    },
    // filteredProducts() {
    //   if (!this.search) {
    //     return this.Products; // Return all products if search query is empty
    //   }

    //   const searchTerms = this.search.toLowerCase().split(' ');

    //   return this.Products.filter(product => {
    //     for (let term of searchTerms) {
    //       if (product.name.toLowerCase().includes(term)) {
    //         return true; // Include the product if any search term matches
    //       }
    //     }
    //     return false; // Exclude the product if no search term matches
    //   });
    // },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required)
        errors.push("Please enter your business name");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required)
        errors.push("Please enter your phone number");
      return errors;
    },
    // invoiceTotal() {
    //   let total = 0;
    //   this.Order.OrderDetails.map((row) => {
    //     total += row.total;
    //   });
    //   return total;
    // },
  },
  watch: {
    options: {
      handler() {
        this.getProduct();
      },
      deep: true,
    },
    search: _.debounce(function () {
      // this.resetProduct()
      if (this.search === null) {
        this.getProduct();
      } else {
        if (this.search.length > 1 || this.search.length === 0) {

          this.getProduct();

        }
      }

    }, 300),
    // search(val) {
    //   if (!val) {
    //     this.search = "";
    //   }
    // },
  },
  async created() {
    if (this.$route.params.id && this.public) {
      const id = this.$route.params.id;
      let result = id.includes("-");

      if (result) {
        const codeNid = id.split("-");
        this.code = hashids.decode(codeNid[0]).toString();
        this.productId = hashids.decode(codeNid[1]).toString();
      } else {
        this.code = hashids.decode(id).toString();
      }

      // console.log('productIdproductId', this.productId)
      await orderService.getBusinessInfo(this.code).then((response) => {
        // console.log("orderService get business", response);
        this.business = response.data.business;
      });
      await this.getCategory()
      this.getPriceRange()
      // if (this.$vuetify.breakpoint.xsOnly) {
      await this.getProduct();
      // }

      if (localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`)) {
        this.Cart = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`))
      }

      if (this.business?.metadata?.remoteOrderCategory) {
        this.categorySearch = true
      } else {
        this.categorySearch = false
      }
      this.setProductOnCart()
      // this.tabChange()
    } else if (this.currentBusiness) {
      this.business = this.currentBusiness
      await this.getCategory()
      this.getPriceRange()
      await this.getProduct();

      if (this.business?.metadata?.remoteOrderCategory) {
        this.categorySearch = true
      } else {
        this.categorySearch = false
      }
      this.setProductOnCart()
    }
  },
  // mounted() {
  //   this.$refs.dataContainer.addEventListener("scroll", this.handleScroll);
  // },
  // beforeDestroy() {
  //   // Clean up the scroll listener when the component is destroyed
  //   this.$refs.container.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    closeCartDialog() {
      console.log('i got called')
      this.addCartDialog = !this.addCartDialog

    },
    // deleteItem(index) {
    //   // Remove the item from the Cart array
    //   this.Cart.splice(index, 1);
    // },
    submitFilter() {
      this.menu = !this.menu
      this.getProduct()
    },
    BackToOrder() {
      this.$emit("cartItems", this.Cart)
      localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`)
    },
    resetProduct() {
      this.Products = []
      this.options.page = 0
      this.limit = 1000
      this.offset = 0
    },
    submitQty(product) {
      if (product.orderQuantity && product.orderQuantity > product.balance) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: 'Exceed order limit',
          });
        return false
      } else {
        product.orderQuantity = parseFloat(product.orderQuantity)
        product.totalAmount = parseFloat(product.orderQuantity) * parseFloat(product.price)
        if (product.orderQuantity !== 0) {
          this.addToCart(product)
        }
        if (product.orderQuantity === 0 && product.cartProduct) {
          const index = this.Cart.findIndex(
            (x) => x.id === product.id
          );
          this.Cart.splice(index, 1);
          product.cartProduct = false
        }
      }
    },
    submitQtyFromCart() {
      if (this.selectedProduct.orderQuantity && this.selectedProduct.orderQuantity > this.selectedProduct.balance) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: 'Exceed order limit',
          });
        return false
      } else {
        this.selectedProduct.orderQuantity = parseFloat(this.selectedProduct.orderQuantity)
        this.selectedProduct.totalAmount = parseFloat(this.selectedProduct.orderQuantity) * parseFloat(this.selectedProduct.price)
        if (this.selectedProduct.orderQuantity !== 0) {
          this.addToCart(this.selectedProduct)
        }
        if (this.selectedProduct.orderQuantity === 0 && this.selectedProduct.cartProduct) {
          const index = this.Cart.findIndex(
            (x) => x.id === this.selectedProduct.id
          );
          this.Cart.splice(index, 1);
          this.selectedProduct.cartProduct = false
        }

        this.addCartDialog = false
        this.setProductOnCart()
      }
    },
    async addToCart(product) {
      // console.log('product', product)
      let found = false
      await Promise.all(
        this.Cart.map(row => {
          if (row.id === product.id) {
            found = true
            if (product.cartProduct) {
              row.orderCount = parseFloat(product.orderQuantity)
              product.cartProduct = false
            } else {
              row.orderCount = parseFloat(product.orderQuantity)
            }
            row.totalAmount = parseFloat(row.orderCount) * parseFloat(row.price)
          }
        })
      )
      if (!found) {
        product.orderCount = parseFloat(product.orderQuantity)
        this.Cart.push(product)
      }

      localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`, JSON.stringify(this.Cart))

    },
    async addToCart1(product) {
      // console.log('product', product)
      let found = false
      await Promise.all(
        this.Cart.map(row => {
          if (row.id === product.id) {
            found = true
            if (product.cartProduct) {
              row.orderCount = parseFloat(product.orderQuantity)
              product.cartProduct = false
            } else {
              row.orderCount = parseFloat(row.orderCount) + parseFloat(product.orderQuantity)
            }
            row.totalAmount = parseFloat(row.orderCount) * parseFloat(row.price)
          }
        })
      )
      if (!found) {
        product.orderCount = parseFloat(product.orderQuantity)
        this.Cart.push(product)
      }

      localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`, JSON.stringify(this.Cart))

    },
    openAdd(item, cartProduct) {
      // console.log('selectedProductselectedProduct', item)
      this.selectedProduct = item
      if (cartProduct) {
        this.selectedProduct.cartProduct = cartProduct
        this.selectedProduct.orderQuantity = parseFloat(this.selectedProduct.orderCount)
      } else {
        this.selectedProduct.orderQuantity = 0
      }
      this.addCartDialog = !this.addCartDialog
    },
    setProductOnCart() {
      this.Cart.forEach(cartItem => {
        // console.log('cartItem', cartItem)
        const product = this.Products.find(product => product.id === cartItem.id);
        if (product) {
          // console.log('product', cartItem.orderQuantity, cartItem.orderCount)
          this.$set(product, 'orderQuantity', cartItem.orderQuantity || 0);
          this.$set(product, 'orderCount', cartItem.orderCount || 0);
        }
      });
    },
    async tabChange(val) {

      this.search = '';
      this.Products = [];

      this.selectedCategory = val.id;

      // Fetch the products
      await this.getProduct();

      this.setProductOnCart()
      // Update Products with data from Cart


      // Debugging logs to verify data
      // console.log('this.Products', this.Products);
      // console.log('this.cart', this.Cart);
      // const product1 = this.Products.find(product => product.id === 9281);
      // console.log('this.product1', product1);
    },
    reset() {
      this.$swal({
        title: "Are you sure?",
        text: `You like to reset your order list`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          this.clearCart()
        }
      });
    },
    async clearCart() {
      this.Cart = []
      localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`)
      await this.getProduct();
      // this.setProductOnCart()
    },
    orderTotal() {
      let value = {
        total: 0,
        count: 0
      }

      this.Cart.map(product => {
        if (product.orderCount) {
          value.count = parseFloat(value.count) + parseFloat(product.orderCount)
          value.total = parseFloat(value.total) + parseFloat(product.totalAmount)
        }
      })

      return value;
    },
    openImageDialog(item) {
      this.selectedProduct = item
      this.imageDialog = !this.imageDialog
    },
    closeImageDialog() {
      this.imageDialog = !this.imageDialog
      this.selectedProduct = null
    },
    copyLink(val) {
      // console.log('val', val)
      copy(val.imeiNumber);
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        })
        .fire({
          icon: "success",
          title: "IMEI number is copy!",
        });

      this.dialog = false;
    },
    async newOrder() {
      console.log('i got pressed')
      this.Order = {
        date: this.$moment().toISOString(),
        note: null,
        OrderDetails: [],
      }
      this.orderCreated = false
      this.$v.$reset();
    },
    getCategoryOrderTotal(obj) {
      let total = 0
      obj.list.map(row => {
        if (row.orderCount) {
          row.totalOrderAmount = row.price * row.orderCount
          total = total + row.totalOrderAmount
        }
      })
      return total
    },
    productOrderCount() {
      for (const property in this.GroupProduct) {
        let total = 0
        let totalOrder = 0
        this.GroupProduct[property].list.forEach(row => {
          if (row.orderCount && row.orderCount > 0) {
            total = total + row.orderCount
            row.totalOrderAmount = row.price * row.orderCount
            totalOrder = totalOrder + row.totalOrderAmount
            // productPrice = total * row.price
          }
        })
        this.GroupProduct[property].order = total
        this.GroupProduct[property].totalAmount = totalOrder
      }
    },
    add(product) {
      if (product.orderQuantity) {
        if (product.orderQuantity && product.orderQuantity >= product.balance) {
          $swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: 'Exceed order limit',
            });
        } else {
          product.orderQuantity++
          product.totalAmount = product.orderQuantity * product.price
        }

      } else {
        product.orderQuantity = 1
        product.totalAmount = product.orderQuantity * product.price
      }
      this.submitQty(product)
      this.$forceUpdate();
    },
    minus(product) {
      // console.log('minus product', product)
      if (product.orderQuantity && product.orderQuantity !== 0) {
        product.orderQuantity--
      }
      // this.productOrderCount()
      this.submitQty(product)
      this.$forceUpdate();
    },
    addInOrderCart() {
      if (this.selectedProduct.orderQuantity) {
        if (this.selectedProduct.orderQuantity && this.selectedProduct.orderQuantity >= this.selectedProduct.balance) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: 'Exceed order limit',
            });
        } else {
          this.selectedProduct.orderQuantity++
          this.selectedProduct.totalAmount = this.selectedProduct.orderQuantity * this.selectedProduct.price
        }

      } else {
        this.selectedProduct.orderQuantity = 1
        this.selectedProduct.totalAmount = this.selectedProduct.orderQuantity * this.selectedProduct.price
      }
      this.$forceUpdate();
    },
    minusInOrderCart() {
      // console.log('minus product', product)
      if (this.selectedProduct.orderQuantity && this.selectedProduct.orderQuantity !== 0) {
        this.selectedProduct.orderQuantity--
      }
      // this.productOrderCount()
      this.$forceUpdate();
    },
    add1(product) {

      if (product.orderCount) {
        if (product.orderCount && product.orderCount >= product.balance) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: 'Exceed order limit',
            });
        } else {
          product.orderCount++
          product.totalAmount = product.orderCount * product.price
        }

      } else {
        product.orderCount = 1
        product.totalAmount = product.orderCount * product.price
      }
      // this.productOrderCount()
      this.$forceUpdate();
    },
    minus1(product) {
      // console.log('minus product', product)
      if (product.orderCount && product.orderCount !== 0) {
        product.orderCount--
      }
      // this.productOrderCount()
      this.$forceUpdate();
    },
    getCategory() {
      if (this.public) {
        return orderService
          .getAllCategory({
            key: this.code,
            remoteOrder: true,
          })
          .then((result) => {
            // this.Categories = result.data
            // // console.log('this.Category', this.Categories)
            // this.selectedCategory = this.Categories[0].id
            this.Categories = result.data.sort((a, b) => {
              if (a.name < b.name) return -1; // `a` comes before `b`
              if (a.name > b.name) return 1;  // `a` comes after `b`
              return 0; // Names are equal
            });

            // Set the first category as selected
            this.selectedCategory = this.Categories.length > 0 ? this.Categories[0].id : null;
          }).catch(error => {
            console.log('error getting product', error)
          })
      } else {
        return categoryService
          .getAll({
            remoteOrder: true,
          })
          .then((result) => {
            this.Categories = result.data.sort((a, b) => {
              if (a.name < b.name) return -1; // `a` comes before `b`
              if (a.name > b.name) return 1;  // `a` comes after `b`
              return 0; // Names are equal
            });

            // Set the first category as selected
            this.selectedCategory = this.Categories.length > 0 ? this.Categories[0].id : null;
          }).catch(error => {
            console.log('error getting product', error)
          })
      }


    },
    loadMoreProduct() {
      this.options.page++
      this.getProduct()
    },
    getPriceRange() {
      if (this.public) {
        return orderService
          .getPriceRange({
            key: this.code,
          })
          .then((result) => {
            if (result) {
              this.max = result.data.max_price
              this.range = [0, this.max]
              // console.log('prince range result', result, this.max, this.range)
            }
          }).catch(error => {
            console.log('error getting product', error)
          })
      } else {
        return productService
          .getPriceRange()
          .then((result) => {
            if (result) {
              this.max = result.data.max_price
              this.range = [0, this.max]
              // console.log('prince range result', result, this.max, this.range)
            }
          }).catch(error => {
            console.log('error getting product', error)
          })
      }

    },
    getProduct() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      if (itemsPerPage > 0 && page) {
        this.offset = itemsPerPage * page - itemsPerPage;
      }
      if (this.search && this.$vuetify.breakpoint.xsOnly) {
        this.limit = 10;
      } else {
        this.limit = itemsPerPage;
      }

      // console.log('page', itemsPerPage, page, this.offset)
      let where = {
        key: this.code,
        hideZero: true,
        remoteOrder: true,
        hideParent: true,
        limit: this.limit,
        offset: this.offset,
        search: this.search,
        orderBy: [...this.options.sortBy, ...this.options.sortDesc],
      }
      // console.log('this.selectedCategory = val.id',this.categorySearch,this.selectedCategory)
      // if (this.categorySearch) {
      if (this.selectedCategory) {
        where.CategoryId = this.selectedCategory
      }
      // }

      if (this.rangeSearch) {
        where.range = this.range
      }

      // console.log('where', where)
      if (this.public) {
        return orderService
          .getPublicProduct(where)
          .then((result) => {
            // if (this.business.metadata && this.business.metadata.dynamicPrice) {
            //   result.data.map(row => {
            //     row.dynamicPrice = this.getDynamicPrice(row, this.business.metadata.dynamicPriceList)
            //   })
            // }
            this.count = result.count
            // console.log('Products', result.data)

            if (this.$vuetify.breakpoint.xsOnly) {
              if (!this.search) {
                this.Products = this.Products.concat(result.data);
              } else {
                this.Products = result.data
              }
            } else {
              this.Products = result.data // [...this.Products, ...result.data];
            }

            this.Products.sort((a, b) => a.name.localeCompare(b.name));


            const grouped = this.Products.reduce((acc, product) => {
              // Group by Department Name
              const departmentName = product.Department ? product.Department.name : 'Unassigned';
              if (!acc[departmentName]) {
                acc[departmentName] = []; // Initialize an array for products in this department
              }

              // Add product to the appropriate department
              acc[departmentName].push(product);

              return acc;
            }, {});

            // Sort the products within each department by Brand name and then by Product name
            Object.keys(grouped).forEach(departmentName => {
              grouped[departmentName] = grouped[departmentName]
                .sort((a, b) => {
                  const brandA = a.Brand ? a.Brand.name : 'Unassigned';
                  const brandB = b.Brand ? b.Brand.name : 'Unassigned';

                  // First, sort by Brand name
                  const brandComparison = brandA.localeCompare(brandB);
                  if (brandComparison !== 0) {
                    return brandComparison;
                  }

                  // If Brands are the same, sort by Product name
                  return a.name.localeCompare(b.name);
                });
            });

            // Convert the grouped object into an array and sort departments alphabetically
            const sortedDepartments = Object.keys(grouped)
              .sort((a, b) => a.localeCompare(b)) // Sort department names alphabetically
              .map(departmentName => ({
                departmentName,
                Products: grouped[departmentName] // Include sorted products
              }));

            // Update the groupedProducts with the final sorted structure
            this.groupedProducts = sortedDepartments;


            this.loading = false;

            // console.log('this.groupedProducts', this.groupedProducts)

          }).catch(error => {
            console.log('error getting product', error)
          })
      } else {
        return productService
          .getAll(where)
          .then((result) => {
            // if (this.business.metadata && this.business.metadata.dynamicPrice) {
            //   result.data.map(row => {
            //     row.dynamicPrice = this.getDynamicPrice(row, this.business.metadata.dynamicPriceList)
            //   })
            // }
            this.count = result.count
            // console.log('Products', result.data)

            if (this.$vuetify.breakpoint.xsOnly) {
              if (!this.search) {
                this.Products = this.Products.concat(result.data);
              } else {
                this.Products = result.data
              }
            } else {
              this.Products = result.data // [...this.Products, ...result.data];
            }

            this.Products.sort((a, b) => a.name.localeCompare(b.name));


            const grouped = this.Products.reduce((acc, product) => {
              // Group by Department Name
              const departmentName = product.Department ? product.Department.name : 'Unassigned';
              if (!acc[departmentName]) {
                acc[departmentName] = []; // Initialize an array for products in this department
              }

              // Add product to the appropriate department
              acc[departmentName].push(product);

              return acc;
            }, {});

            // Sort the products within each department by Brand name and then by Product name
            Object.keys(grouped).forEach(departmentName => {
              grouped[departmentName] = grouped[departmentName]
                .sort((a, b) => {
                  const brandA = a.Brand ? a.Brand.name : 'Unassigned';
                  const brandB = b.Brand ? b.Brand.name : 'Unassigned';

                  // First, sort by Brand name
                  const brandComparison = brandA.localeCompare(brandB);
                  if (brandComparison !== 0) {
                    return brandComparison;
                  }

                  // If Brands are the same, sort by Product name
                  return a.name.localeCompare(b.name);
                });
            });

            // Convert the grouped object into an array and sort departments alphabetically
            const sortedDepartments = Object.keys(grouped)
              .sort((a, b) => a.localeCompare(b)) // Sort department names alphabetically
              .map(departmentName => ({
                departmentName,
                Products: grouped[departmentName] // Include sorted products
              }));

            // Update the groupedProducts with the final sorted structure
            this.groupedProducts = sortedDepartments;


            this.loading = false;

            // console.log('this.groupedProducts', this.groupedProducts)

          }).catch(error => {
            console.log('error getting product', error)
          })
      }


    },

    async getOpenOrder() {
      await orderService
        .openOrder(this.customerId, this.code)
        .then((response) => {
          // console.log("Order Order", response);
          this.OpenOrder = response.data;
        });
    },
    resetOrder() {
      this.getOpenOrder();
      this.orderCreated = false;
      this.Order = {
        date: this.$moment().toISOString(),
        note: null,
        CustomerId: null,
        OrderDetails: [],
      };
    },
    openEditOrder(val) {
      // console.log("EditOrder", val);
      orderService.getOrderByIdPublic(val.id, this.code).then((response) => {
        // console.log("edit order", response);
        this.editOrder = true;
        this.Order = response.data;
        this.OpenOrder = [];
        // this.OpenOrder = response.data;
      });
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    saveOrder() {
      this.Order.OrderDetails = []
      this.Order.isRemoteOrder = true;
      this.Order.name = this.name
      this.Order.phoneNumber = this.phoneNumber
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });

      } else {
        console.log('order submit')
        this.Cart.map(row => {
          if (row.totalAmount && row.totalAmount > 0) {
            this.Order.OrderDetails.push({
              ProductId: row.id,
              name: row.name,
              price: row.price,
              qty: row.orderCount,
              totalPrice: row.totalAmount
            })
          }
        })

        // if (this.productId) {
        //   // console.log('this.GroupProduct', this.GroupProduct)
        //   let imeiList = []
        //   this.GroupProduct[0].list[0].serial.map(row => {
        //     if (row.selected) {
        //       imeiList.push(row.imeiNumber)
        //     }
        //   })
        //   this.Order.OrderDetails.push({
        //     ProductId: this.GroupProduct[0].list[0].id,
        //     name: this.GroupProduct[0].list[0].name,
        //     price: this.GroupProduct[0].list[0].price,
        //     qty: imeiList.length,
        //     totalPrice: imeiList.length * this.GroupProduct[0].list[0].price,
        //     metadata: {
        //       serial: imeiList
        //     }
        //   })
        // } else {
        //   for (const property in this.GroupProduct) {

        //     this.GroupProduct[property].list.forEach(row => {
        //       if (row.orderCount && row.orderCount > 0) {
        //         this.Order.OrderDetails.push({
        //           ProductId: row.id,
        //           name: row.name,
        //           price: row.price,
        //           qty: row.orderCount,
        //           totalPrice: row.totalOrderAmount
        //         })
        //       }
        //     })
        //     // this.GroupProduct[property].order = total
        //     // this.GroupProduct[property].totalAmount = totalOrder
        //   }
        // }


        // console.log('Order', this.Order)
        if (this.Order.OrderDetails.length > 0) {
          return orderService
            .publicCreate(this.code, this.Order)
            .then((result) => {
              // console.log("result", result);
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Order was created!",
                });

              this.orderCreated = true;
              this.sendOrder = false
              this.name = null
              this.phoneNumber = null
              this.clearCart()
              this.addCartDialog = false
            }).catch(error => {
              console.log('order error', error)
            })

        }
      }
    },
    updateOrder() {
      this.Order.isRemoteOrder = true;
      this.Order.CustomerId = this.Customer.id;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        if (this.Order.OrderDetails.length > 0) {
          return orderService
            .publicUpdate(this.Order.id, this.code, this.Order)
            .then(() => {
              // console.log("result", result);
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Order was updated!",
                });

              this.orderCreated = true;
            });
        }
      }
    },
    deleteItem(i, item) {
      this.$swal({
        title: "Are you sure?",
        html: `You want to delete <b>${item.name}</b>?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // this.Order.OrderDetails.splice(i, 1);
          this.Cart.splice(i, 1);
          localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}cart`, JSON.stringify(this.Cart))
        }
      });
    },
    // addRow(val) {
    //   // console.log("addrow", val);
    //   this.Order.OrderDetails.push({
    //     ProductId: val.id,
    //     name: val.Bar.name,
    //     price: val.Bar.price,
    //     totalPrice: val.Bar.price * val.Bar.qty,
    //     qty: val.Bar.qty,
    //   });
    // },
  },
};
</script>

<style scoped>

.listHeight {
  max-height: calc(100vh - 70px);
  width: 100%;
  overflow: auto;
}

.title-scroll {
  width: 275px;
  /* Set the width constraint */
  display: -webkit-box;
  /* Use a flexbox container */
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  /* Set the orientation to vertical */
  overflow: hidden;
  /* Hide the overflowing text */
  text-overflow: ellipsis;
  /* Add ellipsis for clipped text */
  word-wrap: break-word;
  /* Break long words if needed */
}



.touch-view-container {
  max-height: calc(100vh - 70px);
  width: 100%;
  overflow: auto;
}

.openOrderList {
  max-height: calc(100vh - 150px);
  width: 100%;
  overflow: auto;
}
</style>
