<template>
  <div>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon @click="$router.push({ path: '/order' })">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Order</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn color="teal" :fab="$vuetify.breakpoint.xsOnly" text small dark @click="createOrderLink">
        <span v-if="!$vuetify.breakpoint.xsOnly">Order Link</span>
        <v-icon v-else>mdi-link</v-icon>
      </v-btn>
      <v-btn color="teal" :fab="$vuetify.breakpoint.xsOnly" v-if="checkRightStatus(7)" text small dark to="/order/create">
        <span v-if="!$vuetify.breakpoint.xsOnly">New</span>
        <v-icon v-else>add</v-icon>
      </v-btn>
      <v-btn color="teal" :fab="$vuetify.breakpoint.xsOnly" text small dark to="/order/list">
        <span v-if="!$vuetify.breakpoint.xsOnly">List</span>
        <v-icon v-else>list</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  name: "product",
  data() {
    return {};
  },
  created() { },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus", "currentBusiness"]),
  },
  methods: {
    copyCode(code) {
      navigator.clipboard.writeText(code);
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: true,
          timer: 2000,
        })
        .fire({
          icon: "success",
          title: "Order Link is copy",
        });
      // this.$toast.success("Location code was copy", { timeout: 2000 });
    },
    createOrderLink() {
      console.log('process.env', process.env.NODE_ENV)
      const hashId = hashids.encode(this.currentBusiness.code);
      let routeData = null
      if (process.env.NODE_ENV === 'development') {
        routeData = `localhost:8082/public/order/${hashId}`
      } else {
        routeData = `https://app.smplsale.com/public/order/${hashId}`

      }
      this.copyCode(routeData)

      // window.open(routeData.href, "newwindow", "width=850, height=1100");
    }
  }
};
</script>
