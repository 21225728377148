<template>
  <v-container fluid grid-list-lg>
    <v-card :loading="loading" outlined>


      <ListTitle :showBackButton="!CustomerId" :color="$style.purchase.titlebar" :access="checkRightStatus(4)"
        title="Order List" @add="add" />

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar dense elevation="0">

          <v-text-field v-model="search" outlined prepend-inner-icon="search" autofocus dense label="Search" single-line
          style="min-width: 250px;" hide-details clearable></v-text-field>

          <!-- <v-overflow-btn :items="dropdown_font" label="Select font" hide-details class="pa-0"></v-overflow-btn> -->


          <v-divider vertical class="mx-1"></v-divider>

          <v-btn-toggle v-model="orderStatus" @change="getList()"  color="deep-purple accent-3" small group>


            <v-btn value="all">
              <span>All</span>


            </v-btn>
            <v-btn value="pending">
              <span>Pending</span>


            </v-btn>

            <v-btn value="completed">
              <span>Completed</span>


            </v-btn>

          </v-btn-toggle>




          <v-spacer></v-spacer>




          <v-spacer></v-spacer>
          <!-- <DateTimePicker
            :defaultRange="currentBusiness.metadata.invoiceViewLimit ? currentBusiness.metadata.invoiceViewLimit.range : 'since15DaysAgo'"
            :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" /> -->

            <DateTimePicker v-if="hasInvoiceViewLimit" :defaultRange="defaultRange" :range="true" :hideTime="true"
          v-model="dateTime" @submit="getList()" />

        </v-toolbar>
        <v-divider></v-divider>
      </div>

      <v-card-text class="mb-0 pb-0" v-else>
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
              hide-details clearable>
            </v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="12" md="3" sm="12" v-if="!showAll">
            <DateTimePicker defaultRange="since7DaysAgo" :range="true" v-model="dateTime" @submit="getList()" />
          </v-col>
        </v-row>
      </v-card-text>


      <v-card-text>
        <v-data-table :headers="headers" :items="Invoices" :search="search" :loading="loading"
          @current-items="updateTotal" :sort-by="sortBy" sort-desc :page.sync="page" :items-per-page="itemsPerPage">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left" @click="viewOrder(item)">
                {{ item.date | moment("MM/DD/YYYY hh:mm a") }}
              </td>
              <td class="text-left" @click="viewOrder(item)">
                <span>{{ item.localId }} </span>
                <v-chip v-if="item.isRemoteOrder" color="green lighten-3" small
                  text-color="green darken-4">Online</v-chip>
              </td>
              <td class="text-left" v-if="!CustomerId" @click="viewOrder(item)">
                <div v-if="item.Customer">{{ item.Customer.name }}</div>
                <div v-else>{{ item.metadata.name }} <v-chip small>Unknown Customer</v-chip></div>
              </td>

              <td class="text-right" @click="viewOrder(item)">
                {{ item.total | currency }}
              </td>
              <td class="text-left">
                <v-chip color="green lighten-4" small v-if="item.completeDate"
                  text-color="green darken-4">Completed</v-chip>
                <v-chip color="deep-orange lighten-3" small v-else text-color="deep-orange darken-4">Pending</v-chip>
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item v-if="!CustomerId" @click="viewProfile(item)">
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!CustomerId"> </v-divider>
                    <v-list-item @click="viewOrder(item)">
                      <v-list-item-title>View Order</v-list-item-title>
                    </v-list-item>
                    <v-divider> </v-divider>
                    <v-list-item v-if="!item.completeDate" @click="editOrder(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteOrder(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!item.completeDate"></v-divider>
                    <v-list-item v-if="!item.completeDate" @click="convertToInvoice(item)">
                      <v-list-item-title>Convert to invoice</v-list-item-title>
                    </v-list-item>
                    <!-- <OrderViewLink :OrderId="item.id" /> -->
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
          <template v-slot:body.append="{ headers }" v-if="Invoices.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td :colspan="headers.length - 3" class="text-right font-weight-bold">
                List Total
              </td>

              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td :colspan="2"></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td :colspan="headers.length - 3" class="text-right font-weight-bold">
                Result Total
              </td>

              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td :colspan="2"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import orderService from "../service";
const DateTimePicker = () => import("@/components/DateTimePicker");
// import OrderViewLink from "./OrderViewLink";
import { mapGetters } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  props: {
    CustomerId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "order-list",
  data() {
    return {
      orderStatus: 'all',
      fullTotal: 0,
      total: 0,
      dateTime: {
        start: this.$moment().startOf("days").subtract(7, "days").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      count: null,
      selectedPayee: null,
      headers: [],
      Invoices: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "localId",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  watch: {
    CustomerId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  created() {
    // this.getList();
    this.loadHeader();
    // document.title = "smplsale - Order List";
  },
  components: {
    DateTimePicker,
    // OrderViewLink,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus", "currentBusiness"]),
    hasInvoiceViewLimit() {
      if (this.currentBusiness.metadata) {
        return this.currentBusiness && this.currentBusiness.metadata && this.currentBusiness.metadata.invoiceViewLimit;
      } else {
        return true
      }

    },
    defaultRange() {
      if (this.currentBusiness.metadata && this.currentBusiness.metadata.invoiceViewLimit) {
        return this.currentBusiness.metadata.invoiceViewLimit.range
        // return this.hasInvoiceViewLimit ? this.currentBusiness.metadata.invoiceViewLimit.range : 'since15DaysAgo';
      } else {
        return 'since15DaysAgo'
      }
      
    },
  },
  methods: {
    convertToInvoice(order) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to convert this order to invoice?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          orderService
            .getById(order.id)
            .then((obj) => {
              const passData = obj.data;

              this.$router.push({
                name: "invoice-create",
                params: { passData: passData, type: "order" },
              });

              // console.log("test consver purchase", obj);
            })
            .catch((err) => {
              // console.log("got error", err);
            });
        }
      });
    },
    updateTotal(val) {
      this.total = 0;
      val.map((row) => {
        this.total += row.total;
      });
    },
    add() {
      if (this.CustomerId) {
        this.$router.push({
          path: `/order/create?CustomerId=${this.CustomerId}`,
        });
      } else {
        this.$router.push({ path: "/order/create" });
      }
    },
    viewProfile(val) {
      this.$router.push({ path: `/customer/${val.CustomerId}/profile` });
    },
    viewOrder(order) {
      const hashId = hashids.encode(order.id);
      // // console.log('hashId', hashId)
      const routeData = this.$router.resolve({
        path: `/global/order/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadHeader() {
      this.headers = [];
      this.headers.push({
        text: this.$t("labels.date"),
        align: "left",
        value: "date",
        sortable: true,
      });

      this.headers.push({
        text: "Order Number",
        align: "left",
        value: "localId",
        sortable: true,
      });

      if (!this.CustomerId) {
        this.headers.push({
          text: "Customer Name",
          value: "Customer.name",
          align: "left",
          sortable: true,
        });
      }

      // this.headers.push({
      //   text: 'Note',
      //   align: 'left',
      //   value: 'note',
      //   sortable: true,
      // });

      this.headers.push({
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      });

      this.headers.push({
        text: "Status",
        value: "status",
        align: "right",
        sortable: false,
      });

      // this.headers.push({
      //   text: "Payment",
      //   value: "payment",
      //   align: "right",
      //   sortable: true
      // });

      this.headers.push({
        text: "",
        value: "action",
        sortable: false,
      });
    },
    getList() {
      this.loading = true;
      let where = {};
      if (!this.showAll) {
        where = {
          start: this.dateTime.start,
          end: this.dateTime.end,
        };
      }
      if (this.CustomerId) {
        where.CustomerId = this.CustomerId;
      }

      if (!this.currentUser.isAdmin && this.checkRightStatus(50)) {
        where.assingedCustomer = this.currentUser.id
      }

      if (this.orderStatus === 'pending') {
        where.pending = true
      }
      if (this.orderStatus === 'completed') {
        where.completed = true
      }

      if (this.orderStatus === 'all') {
        delete where.completed
        delete where.pending
      }

      return orderService
        .getAll(where)
        .then((response) => {
          //
          this.loading = false;
          this.Invoices = response.data;
          this.fullTotal = 0;
          this.Invoices.map((row) => {
            this.fullTotal += row.total;
          });
          this.count = response.count;
          return response;
        })
        .catch(() => {
          // console.log("error", err);
        });
    },
    editOrder(order) {
      this.$router.push({ path: `/order/${order.id}/edit` });
    },
    deleteOrder(order) {
      if (this.currentBusiness.metadata && this.currentBusiness.metadata.deletePrecaution) {
        this.$swal.fire({
          title: "Are you sure?",
          html: `You won't be able to revert this.</br> <div style="color:red;">Type <span style="font-weight: bold">delete</span> in order remove this</div>`,
          icon: "question",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          console.log('result', result)
          if (result.value && result.value === 'delete') {
            orderService.delete(order.id).then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Order has been deleted.", "success");
                this.getList();
              }
            });
          } else if (result.dismiss === 'cancel') {

          } else {
            this.$swal("Delete", 'Type "delete" to delete item', "error");
          }
        })
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            orderService.delete(order.id).then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Order has been deleted.", "success");
                this.getList();
              }
            });
          }
        });
      }
    },
  },
};
</script>
