import backofficeService from '../../../api/backoffice'
import publicService from '../../../api/public'


export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  getAll(params) {
    return backofficeService.Api().get('order', { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Get specific product
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`order/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  publicGetById(id, params = {}) {
    return publicService.Api().get(`/public/order/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  
  
  /**
   * Create a product. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('order', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Update specific product
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`order/${id}`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  updateStatus(id, data) {
    return backofficeService.Api().put(`order/${id}/status`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Delete product
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`order/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },

}
