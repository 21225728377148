<template>
  <div>
    <v-container grid-list-lg fluid>
      <v-alert v-if="selectedCustomer" border="left" type="error" transition="slide-y-transition" dismissible
        class="mb-1" v-model="selectedCustomer.alert" color="red">
        <strong>{{ selectedCustomer.note }}</strong>
      </v-alert>
      <v-layout column>
        <v-flex>
          <v-card outlined>
            <v-toolbar dense flat color="grey lighten-3">
              <v-toolbar-title>Create Order</v-toolbar-title>
              <v-spacer> </v-spacer>
              <v-btn color="info" small :disabled="Order.OrderDetails.length === 0" @click="saveOrder">{{ invoiceTotal |
                currency }} Save</v-btn>
              <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row class="pl-2" justify="space-between" align="center">
                <v-col cols="12" sm="12" md="3">
                  <AutoComplete :initValue="initCustomer" v-model="selectedCustomer"
                    :errorMessages="selectCustomerError" />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <SalesMan v-model="selectedUser" :initValue="initUser" />
                </v-col>
                <v-col sm="6" md="2">
                  <DateSelector label="Date" v-model="Order.date" />
                </v-col>
                <v-col sm="6" md="2">
                  <v-dialog v-model="orderNote" width="500">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined v-on="on">Order Note</v-btn>
                    </template>

                    <v-card>
                      <ModelTitle title="Order Note" @close="orderNote = !orderNote" />

                      <v-card-text class="mt-3">
                        <v-textarea outlined dense hide-details v-model="Order.note" label="Order Note"></v-textarea>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="orderNote = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col sm="6" md="2">
                  <v-btn-toggle v-model="listType" mandatory color="deep-purple accent-3">
                    <v-btn outlined>
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <v-btn outlined>
                      <v-icon>mdi-border-all</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex v-if="selectedCustomer">
          <ProductSearchBar @submit="addRow" :selectedCustomer="selectedCustomer" />
        </v-flex>
        <v-flex v-else>
          <div class="text-center">Select customer to start order</div>
        </v-flex>
        <div v-if="selectedCustomer">
          <v-flex v-if="$vuetify.breakpoint.xsOnly">
            <div class="text-center">
              <v-dialog v-model="showCart" fullscreen flat class="elevation-0">
                <template v-slot:activator="{ on }">
                  <v-card color="info" dark hover v-on="on">
                    <v-card-text>
                      <div class="text-center font-weight-bold">
                        {{ Order.OrderDetails.length }} rows in cart
                      </div>
                      <div class="text-center">Click here to view cart</div>
                    </v-card-text>
                  </v-card>
                </template>

                <v-card flat>
                  <v-toolbar dark color="#F0C316">
                    <v-btn icon small dark @click="showCart = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Cart</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn text :disabled="Order.OrderDetails.length === 0" @click="saveOrder">{{ invoiceTotal |
                        currency }}
                        Save</v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card outlined tile>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" v-for="(item, index) in Order.OrderDetails" :key="index">
                          <v-card outlined>
                            <v-card-text>
                              <div>
                                <v-btn icon @click="deleteItem(index)">
                                  <v-icon color="red">delete</v-icon>
                                </v-btn>
                                {{ item.name }}
                              </div>
                              <div><span>Qty: </span>{{ item.qty }}</div>
                              <v-row>
                                <v-col><span>Price: </span>{{ item.price | currency }}</v-col>
                                <v-col><span>Total: </span>{{ (item.qty * item.price) | currency }}</v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </div>
          </v-flex>
          <v-flex v-if="!$vuetify.breakpoint.xsOnly">
            <v-card outlined>
              <v-card-text>
                <v-data-table :headers="headers" hide-default-footer :itemsPerPage="1000" id="order-cart"
                  no-data-text="No item on this order yet" :items="Order.OrderDetails">
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td>
                        <v-layout align-center justify-start row fill-height wrap>
                          <v-btn icon @click="deleteItem(index)">
                            <v-icon color="red">delete</v-icon>
                          </v-btn>
                          <v-text-field v-model="item.name" outlined hide-details :disabled="lockProductName"
                            @focus="$event.target.select()" dense></v-text-field>
                        </v-layout>
                      </td>
                      <td
                        v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory"
                        class="text-center">
                        <v-text-field v-model="item.boxQty" :disabled="item.imeiNumber ? true : false" outlined
                          @blur="checkPrice(item)" class="centered-input" hide-details dense
                          @focus="$event.target.select()"></v-text-field>
                      </td>
                      <td
                        v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
                        <div class="text-center">{{ item.unitCountInBox }}</div>
                      </td>
                      <td class="text-right">
                        <v-text-field v-model="item.qty" :disabled="item.imeiNumber ? true : false" outlined
                          @blur="checkPrice(item)" class="centered-input" hide-details dense
                          @focus="$event.target.select()"></v-text-field>
                      </td>
                      <td class="text-right">
                        <v-text-field v-model="item.price" outlined class="al-r" hide-details @blur="checkPrice(item)"
                          @focus="$event.target.select()" dense></v-text-field>
                      </td>

                      <td class="text-right">
                        {{ (item.total) | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card v-if="totalQty > 0" outlined tile>
                <v-card-text>
                  <div class="text-left">
                    <span class="font-weight-medium">{{ totalQty }}</span> Total
                    Item
                  </div>
                </v-card-text>
              </v-card>
            </v-card>
          </v-flex>
        </div>
      </v-layout>

    </v-container>
    <v-dialog v-model="catalogueOrderDialog" fullscreen>
      <v-card>
        <!-- <v-btn color="success" @click="catalogueOrderDialog = !catalogueOrderDialog">text</v-btn> -->
        <CatalogueOrder v-if="catalogueOrderDialog" :public="false" @cartItems="addCatalogueItemsToCart" />
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");
import DateSelector from "@/components/DateSelector";
import AutoComplete from "@/modules/Customer/components/AutoComplete";
import CatalogueOrder from "@/modules/Order/RemoteOrderView/components/SerializeOrderCreate.vue";
import SalesMan from "@/modules/User/components/AutoComplete.vue";
import ProductSearchBar from "@/modules/Order/InHouse/components/ProductSearchBar";
import orderService from "../service";
import { mapGetters } from "vuex";

export default {
  name: "create-order",
  data() {
    return {
      initCustomer: null,
      initUser: null,
      selectedUser: null,
      showCart: false,
      listType: 0,
      orderNote: false,
      selectedCustomer: null,
      catalogueOrderDialog: false,
      Customer: [],
      loading: true,
      Order: {
        date: this.$moment().toISOString(),
        note: null,
        CustomerId: null,
        OrderDetails: [],
      },
      headers: [],
    };
  },
  components: {
    DateSelector,
    AutoComplete,
    CatalogueOrder,
    SalesMan,
    ProductSearchBar,
  },
  mixins: [validationMixin],
  validations: {
    Order: {
      CustomerId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  async created() {
    if (this.$route.query && this.$route.query.CustomerId) {
      this.initCustomer = this.$route.query.CustomerId;
      // const customer = await customerService.getById(this.$route.query.CustomerId)
      // // console.log('djlfkdjf', customer)
      // this.initCustomer = customer.data
    }
    this.headers.push({
      text: "Name",
      align: "left",
      value: "name",
      sortable: false,
    });


    if (this.currentBusiness?.metadata?.boxCountOnInventory) {
      this.headers.push(
        {
          text: 'Box Qty',
          align: "center",
          value: "boxQty",
          width: "100",
          sortable: false,
        },
      );
    }

    if (this.currentBusiness?.metadata?.boxCountOnInventory) {
      this.headers.push(
        {
          text: "Unit Count",
          align: "center",
          value: "unitCountInBox",
          sortable: false,
        }
      );
    }

    this.headers.push(
      {
        text: 'Qty',
        align: "center",
        value: "qty",
        width: "100",
        sortable: false,
      },
    );


    this.headers.push(
      {
        text: "Price",
        align: "right",
        value: "price",
        sortable: false,
      },
      {
        text: "Total",
        align: "right",
        value: "total",
        sortable: false,
      }

    );

  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    ...mapGetters("global", ["currentBusiness"]),
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    },
    totalQty() {
      let total = 0;
      if (this.Order.OrderDetails) {
        this.Order.OrderDetails.map((row) => {
          total += parseFloat(row.qty);
        });
      }

      return total;
    },
    selectCustomerError() {
      const errors = [];
      if (!this.$v.Order.CustomerId.$dirty) return errors;
      if (!this.$v.Order.CustomerId.required)
        errors.push("Pleaes select customer");
      if (!this.$v.Order.CustomerId.minValue)
        errors.push("Pleaes select customer");
      return errors;
    },
    invoiceTotal() {
      let total = 0;
      this.Order.OrderDetails.map((row) => {
        total += row.total;
      });
      return total;
    },
  },
  watch: {
    selectedCustomer(val) {
      // console.log("selectedCustomer", this.selectedCustomer);
      this.Order.CustomerId = this.selectedCustomer.id;
      if (val) {
        if (val.SalesRep) {
          this.initUser = val.SalesRep;
        } else {
          this.initUser = this.currentUser;
        }
        if (this.listType === 1) {
          this.catalogueOrderDialog = true
        }
      } else {
        this.initUser = this.currentUser;
      }
    },
    listType(val) {
      if (val === 1 && this.selectedCustomer) {
        this.catalogueOrderDialog = true
      }
    }
  },
  methods: {
    checkPrice(item) {
      let total = 0
      if (item.unitCountInBox && item.unitCountInBox > 0) {
        total = ((parseFloat(item.qty) * item.unitCountInBox) * parseFloat(item.price)).toFixed(2)
      } else {
        total = (parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)
      }
      item.total = parseFloat(total)
    },
    cancel() {
      this.$router.go(-1);
    },
    addCatalogueItemsToCart(items) {
      let total = 0
      items.map(item => {
        total = item.price * item.orderQuantity
        this.Order.OrderDetails.push({
          ProductId: item.id,
          name: item.name,
          price: item.price,
          total: total,
          unitCountInBox: item.unitCountInBox,
          boxQty: 1,
          qty: item.orderQuantity,
        });
      })
      this.listType = 0
      this.catalogueOrderDialog = false
    },
    saveOrder() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        if (this.Order.OrderDetails.length > 0) {
          this.Order.SalesRepUserId = this.selectedUser.id;
          return orderService.create(this.Order).then(() => {
            // console.log("result", result);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Order was created!",
              });

            this.$router.go(-1);
          });
        }
      }
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.Order.OrderDetails.splice(i, 1);
        }
      });
    },

    addRow(val) {
      let total = 0
      // if (val.unitCountInBox && val.unitCountInBox > 0 && this.currentBusiness?.metadata?.boxCountOnInventory) {
      //   total = ((val.Bar.qty * val.unitCountInBox) * val.Bar.price).toFixed(2)
      //   total = parseFloat(total)
      // } else {
      //   total = val.Bar.price * val.Bar.qty
      // }
      total = val.Bar.price * val.Bar.qty
      this.Order.OrderDetails.push({
        ProductId: val.id,
        name: val.Bar.name,
        price: val.Bar.price,
        total: total,
        unitCountInBox: val.unitCountInBox,
        boxQty: val.Bar.boxQty,
        qty: val.Bar.qty,
      });
    },
  },
};
</script>

<style scoped>
#invoice-cart {
  height: calc(100vh - 528px);
  overflow: auto;
}
</style>
